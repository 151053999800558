<template>
    <page-with-navigation>
        <div class="rec-overview">
            <div class="actions">
                <button class="primary" @click="$router.push({name: 'recommend'})">
                    <fa-icon icon="plus"></fa-icon>
                    {{ $t('orderRecOverview.new') }}
                </button>
            </div>
            <order-recommendation-list></order-recommendation-list>
            <sent-recommendations-list></sent-recommendations-list>
        </div>
    </page-with-navigation>
</template>
<script>
import OrderRecommendationList from "@/FrontEnd/components/OrderRecommendationList";
import PageWithNavigation from "@/FrontEnd/components/PageWithNavigation";
import SentRecommendationsList from "@/FrontEnd/components/SentRecommendationsList";
export default {
    name: "OrderRecommendationOverview",
    components: {SentRecommendationsList, PageWithNavigation, OrderRecommendationList}
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";

    .newButton{
      padding: $button-padding;
      border-radius: unset;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 1rem;
      display: inline-block;
      position: relative;
      font-weight: bold;
      text-decoration: none;
      text-align: left;
      cursor: pointer;
      border: $button-border;
      margin-right: .3125rem;
      background: $brand_color;
      line-height: 2.25rem;
      height: 2.625rem;
      color: white;
    }
    .rec-overview {
        display: flex;
        flex-direction: column;
        height: 100%;
        &>* {
            overflow: hidden;
            min-height: 13rem;
        }
    }
    div.actions {
        min-height: 2.8rem;
        height: 2.8rem;
        width: 100%;
        text-align: right;
    }
</style>