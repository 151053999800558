<template>
  <div>
    {{totalNetPrice}}
  </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
  name: "OrderSheetShipmentTotal",
  props: {
    shipments: ShipmentDates
  },
  computed:{
    formatter(){
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'})
    },
    totalNetPrice() {

      let total = 0;
      for(let shipment of this.shipments.getAllDates()) {
        total += shipment.totalNet;
      }

      return this.formatPrice(total);
    }
  },
  methods:{
    formatPrice(price){
      return this.formatter.format(price);
    },
  }

}
</script>

<style scoped>

</style>