<template>
  <div>
    <label :for="'volume-discounts-'+_uid">{{ $t('orderSeasonForm.volumeDiscount') }}</label>
    <input :id="'volume-discounts-'+_uid" type="checkbox" v-model="volumeDiscount">
    <div v-if="volumeDiscount">
      {{ $t('volumeDiscount.info')}}
    </div>
  </div>
</template>

<script>
export default {
  name: "VolumeDiscount",
  props: {
    value:{
      type: Boolean,
      default: null
    }
  },
  computed: {
    volumeDiscount: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/input";
@import "@/FrontEnd/styles/layout";
</style>