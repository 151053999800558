<template>
  <div>
    {{totalNetPrice}}
  </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
  name: "OrderSheetShipmentTotal",
  props: {
    articles: {
      type: Array,
    },
    shipments: ShipmentDates,
    shipmentId: Number
  },
  computed:{
    formatter(){
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'})
    },
    totalNetPrice() {


      let shipment = this.shipments.getShipmentById(this.shipmentId);


      let sum = shipment.getTotalNetPrice(this.articles);
      let quantity = shipment.getTotalQuantity(this.articles);
      shipment.totalAmount = sum;


      let total = shipment.totalAmount - shipment.staggeredDiscount - shipment.shipmentDiscount;

      shipment.totalNet = total;

      return this.formatPrice(total);
      }
  },
  methods:{
    formatPrice(price){
      return this.formatter.format(price);
    },
  }

}
</script>

<style scoped>

</style>