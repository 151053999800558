<template>
  <div>
    <span style="text-align:center;width:10%;min-width:2rem;float:left" >{{ shipmentDiscountPercentage + "%" }}</span><span style="text-align: right;width:auto;float:right">{{ "- " + shipmentDiscountAmountFormatted  }}</span>
  </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
  name: "OrderSheetShipmentDiscount",
  props: {
    shipment:{
      type:Object
    },
    shipmentDiscount:{
      type: Object,
      default:{
        active: false
      }
},
    articles: {
      type: Array,
      default: [],
    },
    shipments: ShipmentDates,
    shipmentId: Number,
    initialDiscountPercentage: {
      type: Number,
      default: 0
    },
  },
  created(){
      let validShipmentCount = 0;



      for (let shipment of this.shipments) {
        if (shipment.totalAmount >= this.shipmentDiscount.min) {
          validShipmentCount += 1;
        }
      }
      if (validShipmentCount >= this.shipmentDiscount.count) {
        for (let shipment of this.shipments) {
          if(shipment.id === this.shipment.id && this.shipment.status){
            shipment.shipmentDiscount = shipment.totalAmount * (this.shipmentDiscount.percentage / 100);
          }
        }
        this.initialDiscountPercentage =  this.shipmentDiscount.percentage
    } else {
      for (let shipment of this.shipments) {
        shipment.shipmentDiscount = 0;
      }
    }

  },
  computed:{
    formatter(){
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'})
    },
    shipmentDiscountAmountFormatted(){
      return this.formatPrice(this.shipmentDiscountAmount);
    },

    shipmentDiscountPercentage(){


        if (this.shipmentDiscount.active) {

          if(this.shipment.status) {


            let validShipmentCount = 0;

            for (let shipment of this.shipments) {

              if (shipment.totalAmount >= this.shipmentDiscount.min) {
                validShipmentCount += 1;
              }
            }
            if (validShipmentCount >= this.shipmentDiscount.count) {
              for (let shipment of this.shipments) {
                if (shipment.id === this.shipment.id) {
                  shipment.shipmentDiscount = shipment.totalAmount * (this.shipmentDiscount.percentage / 100);
                }
              }

              return this.shipmentDiscount.percentage;


            } else {
              for (let shipment of this.shipments) {
                shipment.shipmentDiscount = 0;
              }
            }
          }else{
            let validShipmentCount = 0;

            for (let shipment of this.shipments) {

              if (shipment.totalAmount >= this.shipmentDiscount.min) {
                validShipmentCount += 1;
              }
            }
            if (validShipmentCount >= this.shipmentDiscount.count) {
              for (let shipment of this.shipments) {
                if(shipment.id === this.shipment.id){
                  shipment.shipmentDiscount = shipment.totalAmount * (this.initialDiscountPercentage / 100);
                }
              }

              return this.initialDiscountPercentage;


            }else {
              for (let shipment of this.shipments) {
                shipment.shipmentDiscount = 0;
              }
            }
          }
        }


        return 0;

    },
    shipmentDiscountAmount(){
      let shipment = this.shipments.getShipmentById(this.shipmentId);

      return shipment.totalAmount * (this.shipmentDiscountPercentage/100);
    },
    totalNetPrice() {


      let shipment = this.shipments.getShipmentById(this.shipmentId);


      let sum = shipment.getTotalNetPrice(this.articles);
      let quantity = shipment.getTotalQuantity(this.articles);
      shipment.totalAmount = sum;


      let total = shipment.totalAmount - shipment.staggeredDiscount;

      shipment.totalNet = total;

      return this.formatPrice(total);
    }
  },
  methods:{
    formatPrice(price){
      return this.formatter.format(price);
    },
  }

}
</script>

<style scoped>

</style>