<template>
    <div v-if="active" class="overlay" @click.self="cancelModal">
        <div class="dialog message" v-if="isMessage">
            <p class="message-wrapper">{{ message }}</p>
            <div class="toolbar">
                <button v-if="isConfirm" type="submit" @click="cancelModal">
                    {{ $t('dialog.cancel') }}
                </button>
                <button class="primary" type="submit" @click="confirmModal">
                    {{ $t('dialog.confirm') }}
                </button>
            </div>
        </div>
        <div v-else class="dialog">
            <div class="title-bar">
                <button class="slim close" @click="cancelModal"><fa-icon icon="times"></fa-icon></button>
            </div>
            <component :is="component" v-model="componentData" class="message-wrapper"></component>
            <div class="toolbar" v-if="isConfirm">
                <button @click="cancelModal">
                    {{ $t('dialog.cancel') }}
                </button>
                <button class="primary" @click="confirmModal">
                    {{ $t('dialog.confirm') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/FrontEnd/eventbus";

export default {
    name: "ModalOverlay",
    data() {
        return {
            active: false,
            message: '',
            confirmCallBack: null,
            component: null,
            componentData: null
        };
    },
    created() {
        EventBus.$on('modal-requested', this.openModal);
        EventBus.$on('confirm-requested', this.openConfirm);
        EventBus.$on('modal-component-requested', this.openComponent);
    },
    methods: {
        openModal(msg, callback) {
            this.confirmCallBack = typeof callback === 'undefined' ? null : callback;
            this.message = msg;
            this.active = true;
        },
        confirmModal() {
            if (this.confirmCallBack !== null) {
                this.confirmCallBack(this.componentData);
            }
            this.active = false;
            this.message = '';
            this.component = null;
        },
        cancelModal() {
            this.confirmCallBack = null;
            this.active = false;
            this.message = '';
            this.component = null;
        },
        openConfirm(msg, callback) {
            this.confirmCallBack = callback;
            this.message = msg;
            this.active = true;
        },
        openComponent(component, callback) {
            this.confirmCallBack = typeof callback === 'undefined' ? null : callback;
            this.component = component;
            this.active = true;
        }
    },
    computed: {
        isConfirm() {
            return this.confirmCallBack !== null;
        },
        isMessage() {
            return this.component === null && this.message;
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/input";
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    background-color: rgba(255,255,255,.6);
}
.dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    width: 90vw;
    max-width: 40rem;
    min-height: 18rem;
    max-height: 90vh;
    border: $content-box-border;
    background: #fff;
    box-shadow: $highlight-drop-shadow;
    overflow-y: auto;

    &.message {
        display: flex;
    }

    & .toolbar {
        @include tool-bar;
        position: absolute;
        bottom: 0;
    }
    & .title-bar {
        @include tool-bar;
        position: sticky;
        top: 0
    }
    & .close>* {
        margin: 0 $slim-padding;
    }
    &>.message-wrapper {
        box-sizing: border-box;
        padding: $regular-padding;
    }
    &>p.message-wrapper {
      text-align: center;
        font-size: 1.2rem;
        flex: 2;
        align-self: center;
    }
}
</style>