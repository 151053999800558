<template>
    <div class="msg-wrapper">
        <h2>{{ $t('noSeasonFound.title') }}</h2>
        <p>{{ $t('noSeasonFound.desc') }}</p>
        <button class="primary" @click="logout">{{ $t('noSeasonFound.logout') }}</button>
    </div>
</template>

<script>
export default {
    name: "NoOrderSeasonFound",
    methods: {
        logout() {
            this.$store.dispatch('logout');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    @import "@/FrontEnd/styles/layout";
    .msg-wrapper {
        @include center-middle-content;
        padding: $regular-padding;
    }

    .logoutButton{
      padding: $button-padding;
      border-radius: unset;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 1rem;
      display: inline-block;
      position: relative;
      font-weight: bold;
      text-decoration: none;
      text-align: left;
      cursor: pointer;
      border: $button-border;
      margin-right: .3125rem;
      background: $brand_color;
      line-height: 2.25rem;
      height: 2.625rem;
      color: white;
    }
</style>