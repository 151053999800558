<template>
  <div>
    <label for="shipment-discount-active">Test{{ $t('orderSeasonForm.shipmentDiscount.status')}}</label>
    <input id="shipment-discount-active" type="checkbox" v-model="shipmentDiscount.status">
      <div v-if="shipmentDiscount.status">
        <label for="'shipment-discount-minShipmentCount'">{{ $t('orderSeasonForm.shipmentDiscount.minShipmentCount')}}</label>
        <input id="'shipment-discount-minShipmentCount'" type="text" v-model="shipmentDiscount.minShipmentCount">
        <label for="'shipment-discount-minAmountPerShipment'">{{ $t('orderSeasonForm.shipmentDiscount.minAmountPerShipment')}}</label>
        <input id="'shipment-discount-minAmountPerShipment'" type="text" v-model="shipmentDiscount.minAmountPerShipment">
        <label for="'shipment-discount-percentage'">{{ $t('orderSeasonForm.shipmentDiscount.percentage')}}</label>
        <input id="'shipment-discount-percentage'" type="text" v-model="shipmentDiscount.percentage">
      </div>
  </div>
</template>

<script>
import DiscountShipment from "@/FrontEnd/models/DiscountShipment";
export default {
  name: "DiscountShipmentCount",
  props:{
    shipmentDiscount:{
        type: Array
    }
  }
}
</script>

<style scoped lang="scss">

</style>