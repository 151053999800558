<template>
  <div>
    {{totalNetPrice }}
  </div>

</template>

<script>
import Shipment from "@/FrontEnd/models/Shipment";
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
  name: "OrderSheetShipmentSum",
  props: {
    shipmentId : Number,
    shipments: {
      type: ShipmentDates
    },
    articles: {
      type: Array,
      default: [],
    }
  },
  computed:{
    formatter(){
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'})
    },
    totalNetPrice() {
      let shipment = this.shipments.getShipmentById(this.shipmentId)

      let sum = shipment.getTotalNetPrice(this.articles);
      let quantity = shipment.getTotalQuantity(this.articles);

      shipment.totalAmount = sum;
      shipment.totalQuantity = quantity;



      return this.formatPrice(sum);
    }
  },
  methods:{
    formatPrice(price){
      return this.formatter.format(price);
    },
  }


}
</script>

<style scoped>

</style>