<template>
    <div :class="{'error-input':invalidAmount}">
        <input
            class="slim"
            type="number"
            v-model="amount"
            :disabled="!disabled"
            :step="step"
            :min="min"
            @blur="(e) => {checkAmount(); $emit('blur', e)}"
            @focus="(e) => $emit('focus', e)"
        />
        <span class="error-msg" v-if="invalidAmount">
            {{ $t('article.invalidAmount', {min,step}) }}
        </span>
    </div>
</template>

<script>
import Shipment from "@/FrontEnd/models/Shipment";

export default {
    name: "OrderSheetArticleShipmentAmount",
    props: {
        shipment: {
            type: Shipment
        },
        articleId: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        step: {
            type: Number,
            default: 1
        },
        min: {
            type: Number,
            default: 1
        }
    },
    created() {
        this.checkAmount();
    },
    data() {
        return {
            invalidAmount: false
        }
    },
    computed: {
        amount: {
            get() {
                return this.shipment.getAmount(this.articleId);
            },
            set(amount) {
                amount = parseInt(amount);
                if (isNaN(amount)) {
                    amount = 0;
                }
                if ((amount === 0 || amount > this.min) && amount % this.step === 0) {
                    this.invalidAmount = false;
                }
                this.shipment.setAmount(this.articleId, amount);
            }
        }
    },
    methods: {
        checkAmount() {


            this.invalidAmount = false;
            let amount = this.shipment.getAmount(this.articleId);

            if ((amount > 0 && amount < this.min) || amount % this.step !== 0) {
                this.invalidAmount = true;
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    input[type=number] {
        width: 7.2rem;
        text-align: right !important;
    }
    input:disabled {
      background-color:lightgray;
    }
    span.error-msg {

        display: block;
        font-size: .8rem;
    }
</style>