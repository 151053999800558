<template>
    <tr :class="{ data: true, 'active-input': isActive }">
        <!--<td><img v-if="article.imgPath" :src="articleImg(article.imgPath)"/></td>-->
        <td class="number art-number">{{ article.nr }}</td>
        <!--<td>{{ article.langCluster }}</td>-->
        <td>
            <a :href="articleUrl(article.id, article.nr)" tabindex="-1" target="_blank">{{ article.name }}</a>
        </td>
        <td class="number casepack">{{ article.casePackSize }}</td>
        <td v-if="singleSelection === false && article.usesStaggeredQuantity && article.preorderMOQ < article.staggeredQuantity" class="moq">{{article.staggeredQuantity}}</td>
        <td v-else-if="singleSelection === false && article.preorderMOQ > 1" class="moq">{{article.preorderMOQ}}</td>
        <td v-else-if="singleSelection === false" class="moq">1</td>
        <td v-if="singleSelection === true" class="veValue">{{ article.staggeredQuantity}}</td>
        <td v-else-if="article.usesStaggeredQuantity && singleSelection === false" class="veValue">{{ article.staggeredQuantity}}</td>
        <td v-else class="veValue"> 1</td>
        <td ><div class="veSelect"><div v-if="singleSelection"><input type="checkbox" v-model="isVESelected"></div></div></td>
        <td class="gtin">{{ article.gtin }}</td>
        <td class="currency">{{netPriceFormatted}}</td>
        <td class="currency">{{rlp}}</td>
        <td v-if="singleSelection">
            <input type="checkbox" v-model="isSelected">
        </td>
        <td v-else-if="!singleSelection && article.usesStaggeredQuantity"  class="shipment-amount" v-for="date in shipmentDates.dates" :key="date.id">
            <div>
            </div>
            <order-sheet-article-shipment-amount
                :article-id="article.id.toString()"
                :disabled="date.status"
                :shipment="date"
                :step="article.staggeredQuantity"
                :min="moq"
                @focus="isActive = true"
                @blur="isActive = false"
            >
            </order-sheet-article-shipment-amount>
        </td>
      <td v-else-if="!singleSelection && !article.usesStaggeredQuantity"  class="shipment-amount" v-for="date in shipmentDates.dates" :key="date.id">
        <div>
        </div>
        <order-sheet-article-shipment-amount
            :article-id="article.id.toString()"
            :disabled="date.status"
            :shipment="date"
            :step=1
            :min="moq"
            @focus="isActive = true"
            @blur="isActive = false"
        >
        </order-sheet-article-shipment-amount>
      </td>
        <td v-if="shipmentDates.getAmountOfDates() > 1" class="number">{{ totalAmount }}</td>
        <td v-if="singleSelection === false" class="number currency">{{ totalNetPrice}}</td>
    </tr>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import OrderSheetArticleShipmentAmount from "@/FrontEnd/components/OrderSheetArticleShipmentAmount";
import config from "@/FrontEnd/config";

const articleIdParts = /^([^~]+)~.*/;

export default {
    name: "OrderSheetArticleRow",
    components: {OrderSheetArticleShipmentAmount},
    data() {
        return {
            amounts: {},
            isActive: false
        }
    },
    props: {
        article: {
            type: Object
        },
        shipmentDates: {
            type: ShipmentDates
        },
        singleSelection: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        pricegroup:{
          type: String,
          default: 'EH'
        }
    },
    computed: {
        step(){
          if(this.article.usesStaggeredQuantity){
            return this.article.staggeredQuantity
          }else
          {
            return 1
          }
        },
        moq(){
          if(this.article.preorderMOQ > this.step){
            return this.article.preorderMOQ
          }else
          {
            return this.step
          }
        },
        formatter(){
          return new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'})
        },
        netPriceFormatted(){

              return this.formatPrice(this.netPrice);
        },

        netPrice(){

          for (const index in this.article.prices) {
            let price = this.article.prices[index];

              if(price.pricegroup === this.pricegroup){
                  this.article.netPrice = price.netPrice;

                  return price.netPrice;
              }
          }
        },
        rlp(){
          for (const index in this.article.prices) {
            let price = this.article.prices[index];
            if(price.pricegroup === this.pricegroup){
              return this.formatPrice(price.rlp);
            }
          }
        },
        totalAmount() {

            let amount = 0;
            this.shipmentDates.getAllDates().forEach((date) => {
                amount += date.getAmount(this.article.id);
            });
            return amount;
        },
        totalNetPrice() {
            return this.formatPrice(this.totalAmount * this.netPrice);

        },

        isSelected: {
            get() {

                return this.shipmentDates.getArticleAmount(this.article.id) > 0;

            },
            set(selected) {
                if (selected) {
                    this.shipmentDates.getFirst().setAmount(this.article.id, 1);
                    return;
                }
                this.shipmentDates.getFirst().setAmount(this.article.id, 0);
            }
        },
        isVESelected: {
          get() {
            return this.shipmentDates.getFirst().getVEUsage(this.article.id);
          },
          set(selected) {
            if (selected) {
              this.shipmentDates.getFirst().setVEUsage(this.article.id, true);
              return;
            }
            this.shipmentDates.getFirst().setVEUsage(this.article.id, false);
          }
        }
    },
    methods: {

        formatPrice(price){
          return this.formatter.format(price);
        },
        articleUrl(articleId, articleNr) {
            let match = articleId.match(articleIdParts);
            if (match !== null) {
                return config.shopUrl + 'detail/index/sArticle/' + match[1] + '?number=' + articleNr;
            }
            return config.shopUrl + `detail/index/sArticle/${articleId}` + '?number=' + articleNr;
        },
        articleImg(imgPath) {
            return config.shopUrl + imgPath;
        }
    }
}
</script>

<style scoped lang="scss">
    .art-number {

        min-width: 2.8rem;
        max-width: 7vw;
    }
    .shipment-amount {
        min-width: 5rem;
        max-width: 10vw;
    }
    .currency {
        align: right;
        min-width: 5.2rem;

    }
    .gtin {
        text-align: center;
        min-width: 10rem;
    }
    .casepack{
      text-align: center;
      min-width: 8rem;
    }
    .moq{
      text-align: center;
    }
    .veValue{
      text-align: center;
    }
    .veSelect{
      text-align: left;
    }
    .containsSelection{
      align: center;
      display: inline-block;
    }

</style>
