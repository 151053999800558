import Vue from 'vue';


class Shipment {
    constructor(id, date,active=true) {
        this.id = id;
        this.articleAmounts = {};
        this.articleVEUsage = {};
        this.date = date;
        this.orderNumber = '';
        this.startDate = null;
        this.endDate = null;
        this.orderstop = null;
        this.committed = false;
        this.sum = 0;
        this.staggeredDiscount = 0;
        this.totalAmount = 0;
        this.totalQuantity = 0;
        this.totalNet = 0;
        this.shipmentDiscount = 0;
        this.status = active;

    }
    setOrderNumber(nr) {
        Vue.set(this, 'orderNumber', nr);
    }


    getAmount(articleId) {
        if(!(articleId in this.articleAmounts)) {
            return 0;
        }
        return this.articleAmounts[articleId];
    }

    getVEUsage(articleId) {
        if(!(articleId in this.articleVEUsage)) {
            return false;
        }
        return this.articleVEUsage[articleId];
    }
    setVEUsage(articleId, usage) {
        Vue.set(this.articleVEUsage, articleId, usage);
    }

    setAmount(articleId, amount) {
        Vue.set(this.articleAmounts, articleId, amount);
    }

    getArticlesWithAmount() {
        let articles = [];
        for (let articleId in this.articleAmounts) {
            if (this.articleAmounts[articleId] > 0) {
                articles.push(articleId);

            }
        }
        return articles;
    }

    getArticlesForExport() {
        let articles = [];
        let amount = 0;
        for (let articleId in this.articleAmounts) {
            amount = this.articleAmounts[articleId];
            if (amount > 0) {
                articles.push({"articleId":articleId,"amount":amount});

            }
        }
        return articles;
    }

    checkAmounts(articlesByBrand){



        for (let brand of articlesByBrand) {
            for (let article of brand.articles) {
                let ve = 1;
                if(article.usesStaggeredQuantity){
                    ve = article.staggeredQuantity;
                }
                let moq = article.preorderMOQ;
                if(moq < ve){
                    moq = ve;
                }
                let amount = this.getAmount(article.id);
                if (!((amount === 0 || amount >= moq) && amount % ve === 0)) {
                    return false;
                }

            }
        }
        return true;
    }

    getArticlesWithOptions() {
        let options = {};
        for (let articleId in this.articleAmounts) {
            if (this.articleAmounts[articleId] > 0) {

                options[articleId] = {};

                options[articleId]["usesStaggeredQuantity"] = this.articleVEUsage[articleId];
            }
        }
        return options;
    }

    serialize()
    {
        let isoFromDate = '';
        if (this.startDate instanceof Date) {
            isoFromDate = this.startDate.toISOString();
        } else {
            isoFromDate = (new Date(this.startDate)).toISOString();
        }

        let isoToDate = '';
        if (this.endDate instanceof Date) {
            isoToDate = this.endDate.toISOString();
        } else {
            isoToDate = (new Date(this.endDate)).toISOString();
        }

        let isoOrderStopDate = '';
        if (this.orderstop instanceof Date) {
            isoOrderStopDate = this.orderstop.toISOString();
        } else {
            isoOrderStopDate = (new Date(this.orderstop)).toISOString();
        }

        return {
            active: this.status,
            fromDate: isoFromDate,
            toDate: isoToDate,
            orderstop: isoOrderStopDate,
            articleAmounts: this.articleAmounts,
            customerOrderNumber: this.orderNumber
        }
    }

    getBrandTotal(articlesByBrand, brandId) {
        let regex = new RegExp(`^${brandId}(:.+)*$`);
        return JSON.parse(JSON.stringify(articlesByBrand))
            .filter(function (brand) {
                return regex.test(brand.id)
            })
            .reduce((flatArticles, brand) => flatArticles.concat(brand.articles), [])
            .map((article) => this.getAmount(article.id) * article.netPrice)
            .reduce((price, sum) => sum + price, 0);
    }



    getTotalNetPrice(articlesByBrand) {
        let total = 0;

        for (let brand of articlesByBrand) {
            for (let article of brand.articles) {

                total += (this.getAmount(article.id) * article.netPrice);
            }
        }
        return total;
    }


    getTotalQuantity(articlesByBrand){
        let total = 0;
        for (let brand of articlesByBrand) {
            for (let article of brand.articles) {
                total += this.getAmount(article.id);
            }
        }
        return total;
    }

    setOrderStop(date){
        this.orderstop = date;
    }

    addPeriod(start, end) {
        this.startDate = start;
        this.endDate = end;
    }
}

export default Shipment;