<template>
  <div style="max-width:4rem">
    <div>
      <label class="img-btn" for="language-de">
        <input style="width:4rem;" type="radio" id="language-de" value="de-DE" v-model="$i18n.locale">
        <img src="https://www.katadyn-b2b.com/custom/pcs/icons/flag-de.png" alt="GERMAN">
      </label>
    </div>
    <div>
      <label class="img-btn" for="language-en">
        <input style="width:4rem;" type="radio" id="language-en" value="en-GB" v-model="$i18n.locale">
        <img src="https://www.katadyn-b2b.com/custom/pcs/icons/flag-uk.png" alt="ENGLISH">
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",
  data(){
    return {
      init: false,
      lang: 'de-DE'
    }
  },
  props:{
    initLocale: String,
    default: ''
  },
  created(){
    this.$i18n.locale = this.initLocale;
  },
  computed:{

  }
}
</script>

<style scoped>
.img-btn img{
  width:2rem;
  height:1rem;
}
.img-btn > input{
  display:none
}
.img-btn > img{
  cursor:pointer;
  border:5px solid transparent;
}
.img-btn > input:checked + img{
  border-color: darkgray;
  border-width: 0.1rem;
}
</style>