<template>
    <page-with-navigation>
        <div class="order-rec-form">
            <button class="visibility-toggle slim" @click="toggleBaseData">
                <span v-if="baseDataHidden">{{ $t('orderSeasonForm.show') }}</span>
                <span v-else>{{ $t('orderSeasonForm.hide') }}</span>
            </button>
            <h2>{{ $t('orderRecForm.sentTitle') }}</h2>
            <fieldset class="form" v-show="!baseDataHidden">
                <div :class="{'error-input':nameMissing}">
                    <label :for="'name-'+_uid">{{ $t('orderRecForm.name') }}</label>
                    <input :id="'name-'+_uid" type="text" name="offerName" v-model="offerName"/>
                    <span v-if="nameMissing" class="error-msg">{{ $t('orderRecForm.nameMissing') }}</span>
                </div>
                <div>
                    <label :for="'customer-'+_uid">{{ $t('orderRecForm.customer') }}</label>
                    <customer-selector disabled :id="'customer-'+_uid" v-model="customerId"></customer-selector>
                </div>
            </fieldset>

            <fieldset class="order-sheet-tools">
                <div class="brand-nav">
                    <brand-navigation :brands="articles"></brand-navigation>
                </div>
                <div>
                    <input v-model="hideEmptyPos" :id="'hide-empty-pos-'+_uid" type="checkbox" />
                    <label :for="'hide-empty-pos-'+_uid">{{ $t('orderSheet.hideEmptyPos') }}</label>
                </div>
                <filter-input :placeholder="$t('orderSheet.search')" v-model="articleSearch"></filter-input>
            </fieldset>

            <order-sheet
                class="order-rec-sheet"
                ref="order"
                :hide-empty-positions="hideEmptyPos"
                :article-search-filter="articleSearch"
                @reset-filter-and-show-amounts="resetFilterAndShowAmounts"
            ></order-sheet>

            <div class="order-rec-footer">
                <button @click="showOverview">
                    <fa-icon icon="arrow-left"></fa-icon>
                    {{ $t('orderRecForm.back') }}
                </button>
                <pdf-export :get-payload="preparePayload" target="/api/orderRecommendation/sent/export" :file-name="offerName"></pdf-export>
                <button @click="resendRecommendation">
                    {{ $t('orderRecForm.resend') }}
                    <loading-spinner v-if="sending"></loading-spinner>
                    <fa-icon v-else icon="share"></fa-icon>
                </button>
                <button class="primary" @click="updateRecommendation">
                    {{ $t('orderSentForm.update') }}
                    <loading-spinner v-if="saving"></loading-spinner>
                    <fa-icon v-else icon="save"></fa-icon>
                </button>
            </div>
        </div>
    </page-with-navigation>
</template>

<script>
import PageWithNavigation from "@/FrontEnd/components/PageWithNavigation";
import CustomerSelector from "@/FrontEnd/components/CustomerSelector";
import axios from "axios";
import OrderSheet from "@/FrontEnd/components/OrderSheet";
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import PdfExport from "@/FrontEnd/components/PdfExport";
import FilterInput from "@/FrontEnd/components/FilterInput";
import EventBus from "@/FrontEnd/eventbus";
import BrandNavigation from "@/FrontEnd/components/BrandNavigation";

export default {
    name: "SentRecommendationEditor",
    components: {
        BrandNavigation,
        FilterInput, PdfExport, LoadingSpinner, OrderSheet, PageWithNavigation, CustomerSelector},
    data () {
        return {
            offerName: '',
            articleSearch: '',
            hideEmptyPos: true,
            customerId: null,
            saving: false,
            sending: false,
            nameMissing: false,
            noAmount: false,
            baseDataHidden: false,
            articles: []
        };
    },
    props: {
        offerId: {
            type: Number
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNavigation);
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventNavigation);
    },
    created() {
        if (this.offerId) {
            let sToken = this.$store.state.sessionToken;
            axios.get(`/api/orderRecommendation/sent/${this.offerId}?session=${sToken}`)
                .then(response => {
                    this.offerName = response.data.name;
                    this.customerId = response.data.customer.id;
                    let shipment = this.$refs.order.getShipments().getFirst();
                    if (response.data.articleAmounts) {
                        for (let [articleId, amount] of Object.entries(response.data.articleAmounts)) {
                            shipment.setAmount(articleId, amount);
                        }
                    }
                    this.hideEmptyPos = true;
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                });
            axios.get(`/api/articlesByBrand?session=${sToken}`)
                .then(response => {
                    this.articles = response.data;
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                });
        }
    },
    methods: {
        preventNavigation(event) {
            if (!this.saving) return;
            event.preventDefault();
            event.returnValue = "";
        },
        updateRecommendation() {
            if (!this.validate()) {
                return;
            }
            let sToken = this.$store.state.sessionToken;
            this.saving = true;
            axios.post(`/api/orderRecommendation/sent/${this.offerId}?session=${sToken}`, this.preparePayload())
                .then(() => {
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.updateSentSuccess')
                    );
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                        return;
                    }
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.unknownError')
                    );
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        resendRecommendation() {
            EventBus.$emit('confirm-requested', this.$t('orderRecForm.resendConfirm'), () => {
                let sToken = this.$store.state.sessionToken;
                this.sending = true;
                axios.post(`/api/orderRecommendation/sent/${this.offerId}/resend?session=${sToken}`, this.preparePayload())
                    .then(() => {
                        EventBus.$emit(
                            'modal-requested',
                            this.$t('orderRecForm.resendSuccess')
                        );
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.$store.dispatch('logout', {name: this.$route.name});
                            return;
                        }
                        EventBus.$emit(
                            'modal-requested',
                            this.$t('orderRecForm.unknownError')
                        );
                    })
                    .finally(() => {
                        this.sending = false;
                    });
            });
        },
        showOverview() {
            this.$router.push({name: 'list-recommendations'});
        },
        preparePayload() {
            let shipment = this.$refs.order.getShipments().getFirst();
            return {
                id: this.offerId,
                customerId: this.customerId,
                name: this.offerName,
                articleAmounts: shipment.articleAmounts
            };
        },
        validate() {
            this.nameMissing = false;
            if (!this.offerName) {
                this.nameMissing = true;
            }
            if(!this.$refs.order.hasAnyAmount()) {
                this.noAmount = true;
            }
            if (this.nameMissing || this.noAmount) {
                return false;
            }
            return true;
        },
        resetFilterAndShowAmounts() {
            this.hideEmptyPos = true;
            this.articleSearch = '';
        },
        toggleBaseData() {
            this.baseDataHidden = !this.baseDataHidden;
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/input";
@import "@/FrontEnd/styles/layout";

.order-rec-form {
    @include content-wrapper-inside;
}

.order-rec-sheet {
    flex: 1;
    overflow-y: scroll;
    padding: 0 .4rem;
}
.order-rec-footer {
    @include footer-action-bar;
}
.order-sheet-tools {
    @include tool-bar;
    padding: $regular-padding $large-padding;

    & .brand-nav {
        margin-right: auto;
        margin-left: 0;
    }
}

.visibility-toggle {
    position: absolute;
    right: $large-padding;
}
</style>
