<template>
    <table>
        <tbody>
            <tr>
                <td></td>
                <th v-for="date in shipments.getAllDates()" :key="date.id">{{ $d(date.date, 'short') }}</th>
                <th>{{ $t('volumeDiscountInfo.total') }}</th>
                <th>{{ $t('volumeDiscountInfo.volume') }}</th>
            </tr>
        </tbody>
        <tbody v-for="(cat, catIndex) in categoriesWithTotals" :key="`cat-total-${catIndex}`">
            <tr><th :colspan="fullColSpan">{{ cat.name }}</th></tr>
            <tr v-for="(brand, index) in cat.brands" :key="`brand-info-${index}`">
                <td><router-link :to="`#${getBrandAnchor(brand.id, true)}`">Total {{ brand.name }}</router-link></td>
                <td v-for="(shipmentTotal, index) in brand.shipmentTotals"  :key="`brand-total-${index}`">
                    {{ $n(shipmentTotal, 'currency') }}
                </td>
                <td>{{ $n(brand.total, 'currency') }}</td>
                <td v-if="index === 0" :rowspan="cat.brands.length">
                    {{ Intl.NumberFormat('de', { maximumFractionDigits: 2}).format(volumes[catIndex]) }}%
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import {getBrandAnchor} from "@/FrontEnd/utils/brandAnchorManager";

export default {
    name: "VolumeDiscountInfo",
    props: {
        shipments: ShipmentDates,
        articles: Array,
        totalNetPrice: {
            type: Number,
            default: 0
        },
        volumeDiscounts: Array
    },
    computed: {
        shipmentDates() {
            return this.shipments.getAllDates();
        },
        fullColSpan() {
            return 3 + this.shipments.getAmountOfDates();
        },
        categoriesWithTotals() {
            if (this.volumeDiscounts === null || this.volumeDiscounts.length <= 0) {
                return [];
            }
            return JSON.parse(JSON.stringify(this.volumeDiscounts))
                .map((category) => {
                    category.brands = category.groupIds.map((brandId) => {
                        // look for brand in articles
                        let foundBrand = null;
                        for (let brand of this.articles) {
                            let id = brand.id;
                            let name = brand.name;
                            if (brand.id.includes(':')) {
                                id = brand.id.split(':')[0];
                                name = brand.name.split(/ - /)[0];
                            }
                            if (parseInt(id) === parseInt(brandId)) {
                                foundBrand = {id, name};
                                break;
                            }
                        }
                        if (foundBrand === null) {
                            return null;
                        }
                        let brand = foundBrand;
                        brand.shipmentTotals = [];
                        brand.total = 0;
                        for (let date of this.shipmentDates) {
                            let total = date.getBrandTotal(this.articles, brand.id);
                            brand.shipmentTotals.push(total);
                            brand.total += total;
                        }
                        return brand;
                    }).filter((brand) => brand !== null);
                    return category;
                });
        },
        volumes() {
            if (this.totalNetPrice <= 0) {
                return Array(this.volumeDiscounts.length).fill(0);
            }
            let applies = true;
            let volumes = JSON.parse(JSON.stringify(this.categoriesWithTotals))
                .map((cat) => {
                    let volume = (cat.brands.reduce((total,brand) => total + brand.total, 0) / this.totalNetPrice) * 100;
                    if (volume < 15) {
                        applies = false;
                    }
                    return volume;
                });
            this.$emit('discount-volume-changed', {applies});
            return volumes;
        }
    },
    methods: {
        getBrandAnchor
    }
}
</script>

<style scoped>

</style>