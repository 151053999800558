<template>
    <div class="page-wrapper">
        <advance-order-title v-if="!loading" :initLocale="initLocale"><h1>{{ title }}</h1></advance-order-title>
        <order-sheet
            v-if="!loading && !submitted"
            :articles="articles"
            :dates-from="firstShipmentDate"
            :dates-to="lastShipmentDate"
            :shipments="shipments"
            :period-mode="usePeriods"
            :pricegroup="pricegroup"
            :allow-custom-order-number="true"
            @total-price-changed="updateTotalNetPrice"
            multiple-dates
            :brands="brands"
            :discount-scale="discountScale"
            :total-net-price="totalOrderNetPrice"
            :base-discount="baseDiscount"
            :volume-discounts="volumeDiscounts"
            :shipment-discount="shipmentDiscount"
            :deadlines="deadlines"
            :filtered-articles="filteredArticles"
            :exportCSV="exportCSV"
            ref="sheet"
        ></order-sheet>
        <!--<advance-order-footer
            :discount-scale="discountScale"
            :shipment-dates="shipments"
            :hidden-amount-hint="hiddenAmount"
            :show-totals="true"
            :brands="brands"
            :allow-custom-order-number="allowCustomOrderNumber"
            @reset-filter-and-show-amounts="$emit('reset-filter-and-show-amounts')">
        </advance-order-footer>-->
        <discount-info
            v-if="!loading && !submitted"
            :shipmentDiscount="shipmentDiscount"
            :discount-scale="discountScale"
            :total-net-price="totalOrderNetPrice"
            :base-discount="baseDiscount"
            :volume-discounts="volumeDiscounts"
            :articles="articles"
            :shipments="shipments"
            :deadlines="deadlines"
            :period-mode="usePeriods"
            :minOrderAmount="minOrderAmount"
        >
        </discount-info>
        <div style="text-align:center;" v-if="submitted">
            {{ $t('advanceOrderForm.submitConfirm') }}
        </div>
        <div class="action-footer">
            <nav class="link-list">
                <li><a href="#" @click.prevent="openTerms">{{ $t('advanceOrderForm.terms') }}</a></li>
                <li><a href="#" @click.prevent="openContacts">{{ $t('advanceOrderForm.contact') }}</a></li>
            </nav>
            <button @click="logout" :class="{primary: submitted}">
                <span>{{ $t('advanceOrderForm.logout') }}</span>
                &nbsp;<fa-icon icon="sign-out-alt"></fa-icon>
            </button>
            <button @click="exportOrder">
              {{ $t('advanceOrderForm.export') }} <fa-icon icon="share"></fa-icon>
            </button>
            <button v-if="!fullySubmitted && !submitted" @click="saveOrder">
                {{ $t('advanceOrderForm.save') }} <fa-icon icon="save"></fa-icon>
            </button>
            <button v-if="!fullySubmitted && !submitted" class="primary" @click="submitOrder">
                {{ $t('advanceOrderForm.submit') }} <fa-icon icon="share"></fa-icon>
            </button>

            <div class="order-condition" v-if="!fullySubmitted && !submitted">
                {{ $t('advanceOrderForm.condition') }}
            </div>
        </div>
    </div>
</template>

<script>
import OrderSheet from "@/FrontEnd/components/OrderSheet";
import DiscountInfo from "@/FrontEnd/components/DiscountInfo";
import axios from "axios";
import EventBus from "@/FrontEnd/eventbus";
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import Terms from "@/FrontEnd/pages/Terms";
import Contact from "@/FrontEnd/pages/Contact";
import AdvanceOrderTitle from "@/FrontEnd/components/AdvanceOrderTitle";
import AdvanceOrderFooter from "@/FrontEnd/components/AdvanceOrderFooter";
import {buildBrandIndex} from "@/FrontEnd/utils/brandAnchorManager";
import TermsEN from "@/FrontEnd/pages/TermsEN";
export default {
    name: "AdvanceOrderForm",
    components: {AdvanceOrderTitle, DiscountInfo, OrderSheet,AdvanceOrderFooter},
    props: {
        seasonId: {
            type: String
        }
    },
    data () {
        return {
          exportCSV: [],
          pricegroup: 'EH',
          articleSearchFilter: "",
            brands: [],
            allowCustomOrderNumber: true,
            hiddenAmount: false,
            loading: true,
            title: '',
            articles: [],
            shipmentDiscount: {
              active: false
            },
            fullySubmitted:false,
            minOrderAmount:{
              perShipment: false,
              minAmount: 0
            },
            discountScale: [],
            baseDiscount: null,
            volumeDiscounts: null,
            usePeriods: false,
            deadlines: [],
            totalOrderNetPrice: 0,
            shipments: null,
            submitted: false,
            saving: false,
            initLocale: 'en-GB'
        }
    },
    created() {
        this.shipments = new ShipmentDates();

        let savedDataFound = false;
        let sToken = this.$store.state.sessionToken;
        Promise.all([
            axios.get(`/api/orderSeason/${this.seasonId}?session=${sToken}`)
            .then(response => {

                this.title = response.data.name;
                this.articles = response.data.articles;
                this.brands = this.articles;
                buildBrandIndex(this.brands, true);
                this.pricegroup = response.data.pricegroup;
                this.initLocale = response.data.locale;
                this.usePeriods = response.data.usePeriods || false;
                this.deadlines = response.data.deadlines.map((deadline) => {
                    deadline.date = new Date(Date.parse(deadline.date));
                    deadline.startDate = new Date(Date.parse(deadline.startDate));
                    deadline.endDate = new Date(Date.parse(deadline.endDate)) || new Date(Date.parse(response.data.endDate));
                    return deadline;
                });
                console.log(this.deadlines);
                if (response.data.staggeredDiscounts) {
                    this.discountScale = response.data.staggeredDiscounts;
                }
                if (response.data.volumeDiscounts) {
                    this.volumeDiscounts = response.data.volumeDiscounts;
                }
                if (response.data.baseDiscount) {
                    this.baseDiscount = response.data.baseDiscount;
                }
                if(response.data.minOrderAmount){
                    this.minOrderAmount = response.data.minOrderAmount;
                }
                if(response.data.shipmentDiscount){
                  let shipmentDiscount = response.data.shipmentDiscount;
                  this.shipmentDiscount = {

                      min: shipmentDiscount.minAmountPerShipment,
                      percentage: shipmentDiscount.percentage,
                      active: shipmentDiscount.status,
                      count: shipmentDiscount.minShipmentCount
                    }

                }


            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    this.$router.push({name:'no-season'});
                }
                if (error.response && error.response.status === 401) {
                    this.$store.dispatch('logout', {name: this.$route.name});
                }
            }),
            axios.get(`/api/advanceOrder/${this.seasonId}?session=${sToken}`)
                .then(response => {

                    let allowedShipmentDates = response.data.allowedShipmentDates;
                    console.log(allowedShipmentDates);

                    let allShipmentsSubmitted = true;

                    for(let shipment of response.data.shipments){
                        let orderstopDate = null;
                        let shipmentAllowed = false;
                        let today = new Date();

                        for(let allowedShipmentDate of allowedShipmentDates){
                          if(allowedShipmentDate.startDate === shipment.fromDate &&
                              allowedShipmentDate.endDate === shipment.toDate){
                            console.log(allowedShipmentDate);
                            if(allowedShipmentDate.date){
                              orderstopDate = new Date(Date.parse(allowedShipmentDate.date));
                              shipmentAllowed = today.getTime() < orderstopDate.getTime();
                            }else{
                              orderstopDate = null;
                              shipmentAllowed = false;
                            }




                            allShipmentsSubmitted = false;
                          }
                        }

                        console.log(shipmentAllowed);
                        let shipmentDate = this.shipments.addDate(new Date(Date.parse(shipment.toDate)),{start:new Date(Date.parse(shipment.fromDate)),end:new Date(Date.parse(shipment.toDate))},shipmentAllowed);
                        console.log(shipmentDate);
                        shipmentDate.committed = ('systemOrderNumber' in shipment);

                        if(orderstopDate && shipmentAllowed){
                          shipmentDate.setOrderStop(new Date(Date.parse(orderstopDate)));
                        }

                        for(let articleId in shipment.articleAmounts) {
                            shipmentDate.setAmount(articleId, shipment.articleAmounts[articleId]);
                            shipmentDate.setOrderNumber(shipment.customerOrderNumber || '');
                        }
                    }

                    console.log(this.shipments);
                    this.fullySubmitted = allShipmentsSubmitted;

                    savedDataFound = true;
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                    if (error.response && error.response.status === 403) {
                        this.$router.push({name:'no-season'});
                    }
                })
        ])
            .finally(() => {
                if (!savedDataFound) {
                    if (this.usePeriods) {
                        this.initPeriodShipments();
                    } else {
                        this.initNormalShipments();
                    }
                }
              this.loading = false;
            });


    },
    computed: {
      formatter(){
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol'})
      },
      fulfillsMinShipmentAmount(){
        if(this.minOrderAmount.perShipment){
          for(let shipment of this.shipments){

            if(shipment.totalAmount < this.minOrderAmount.minAmount && !(shipment.totalAmount === 0)){
              return false;
            }
          }
        }

        return true;
      },
      totalNet(){
          return this.shipments.getTotalNetPriceFromShipments();
      },
      fulfillsMinOrderAmount(){

        if(!this.minOrderAmount.perShipment) {
          return this.totalNet >= this.minOrderAmount.minAmount;
        }
        return true;
      },
        shipmentDates() {
          if (this.shipments !== null) {
            return this.shipments;
          }
          return this.defaultShipmentDates;
        },
        firstShipmentDate() {
            let today = new Date();
            for (let deadline of this.deadlines) {
                if (today < deadline.date) {
                    return deadline.startDate;
                }
            }
            return today;
        },
        lastShipmentDate() {
            let today = new Date();
            for (let deadline of this.deadlines) {
                if (today < deadline.date) {
                    return deadline.endDate;
                }
            }
            return today;
        },
      filteredArticles() {
        let result = [];
        this.hideHiddenAmountHint();
        this.brands.forEach(brand => {
          // create deep copy
          brand = JSON.parse(JSON.stringify(brand));

          brand.articles = brand.articles.filter((article) => {
            if (this.articleSearchFilter) {
              if(
                  !article.name.toLowerCase().includes(this.articleSearchFilter.toLowerCase())
                  && !article.nr.toLowerCase().includes(this.articleSearchFilter.toLowerCase())
                  && !article.gtin.toLowerCase().includes(this.articleSearchFilter.toLowerCase())
              ) {
                if (this.shipmentDates.hasArticleAmount(article.id)) {
                  this.showHiddenAmountHint();
                }
                return false;
              }
            }

            if (this.hideEmptyPositions && !this.shipmentDates.hasArticleAmount(article.id)) {
              return false;
            }
            return true;
          });
          if (brand.articles.length > 0) {
            result.push(brand);
          }
        });
        return result;
      },
    },
    methods: {
      formatDate(date) {

        return this.$d(date, 'short');
      },

      searchFilteredArticles(articleId){

        this.filteredArticles.forEach(brand => {
          brand.articles.forEach(article => {

              if(article.id === articleId){

                return article;
              }
          });
        });
        return {};
      },
      exportOrder(){
        let sToken = this.$store.state.sessionToken;
        let shipments = this.$refs.sheet.getShipments().serialize();

        axios.post(`/api/advanceOrder/${this.seasonId}/export?session=${sToken}`,shipments)
            .then(response => {

              let articleRows = response.data.rows;
              let csvContent = "data:text/csv;charset=utf-8,"
                  + articleRows.map(e => e.join(",")).join("\n");

              var encodedUri = encodeURI(csvContent);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", "export.csv");
              document.body.appendChild(link); // Required for FF

              link.click();
            })
            .catch(error => {
              console.log(error);
            })
      .finally(() => {
      });



      },
      checkShipments(){
        for (let shipment of this.shipmentDates){
          if(!shipment.checkAmounts(this.filteredArticles)){
            return false
          }
        }
        return true;
      },
      formatPrice(price){
        return this.formatter.format(price);
      },
        hideHiddenAmountHint() {
          this.hiddenAmount = false;
        },
        initNormalShipments() {
            let firstDate = new Date();
            if (firstDate < this.firstShipmentDate) {
                firstDate = new Date(this.firstShipmentDate);
            }
            // Calculate day increment for the other days
            let increment = Math.round(
                (this.lastShipmentDate.getTime() - firstDate.getTime()) / (1000 * 3600 * 24) / 3
            );
            this.shipments.addDate(new Date(firstDate)); //Today
            this.shipments.addDate(new Date(firstDate.setDate(firstDate.getDate() + increment)));
            this.shipments.addDate(new Date(firstDate.setDate(firstDate.getDate() + increment)));

            let lastDate = new Date(firstDate.setDate(firstDate.getDate() + increment));
            if (lastDate > this.lastShipmentDate) {
                lastDate = new Date(this.lastShipmentDate);
            }

            this.shipments.addDate(lastDate);
        },
        initPeriodShipments() {

            let today = new Date();

            for (let deadline of this.deadlines) {
                // filter out expired periods

                if (today.getTime() >= deadline.date.getTime()) {
                    continue;
                }



              let shipmentDate =  this.shipments.addDate(
                    (today < deadline.startDate) ? new Date(deadline.startDate) : new Date(today),
                    { start: deadline.startDate, end: deadline.endDate}
                );

                shipmentDate.setOrderStop(new Date(Date.parse(deadline.date)))
            }
        },
        updateTotalNetPrice(total) {
            this.totalOrderNetPrice = total;
        },
        submitOrder() {


          if (!this.fulfillsMinShipmentAmount) {
            EventBus.$emit('modal-requested', this.$t('advanceOrderForm.minShipmentAmountNotFulfilled', {min: this.formatPrice(this.minOrderAmount.minAmount)}));
          }
          if (!this.fulfillsMinOrderAmount) {
            EventBus.$emit('modal-requested', this.$t('advanceOrderForm.minOrderAmountNotFulfilled', {min: this.formatPrice(this.minOrderAmount.minAmount)}));
          }
          if (this.fulfillsMinOrderAmount && this.fulfillsMinShipmentAmount) {

            if (this.checkShipments()) {
              let shipments = this.$refs.sheet.getShipments().serialize();

              let sToken = this.$store.state.sessionToken;
              axios.post(`/api/advanceOrder/${this.seasonId}?session=${sToken}`, shipments)
                  .then(() => {
                    this.dirtyFlag = false;
                    this.submitted = true;

                  })
                  .catch();
            } else {
              EventBus.$emit('modal-requested', this.$t('advanceOrderForm.invalidAmountsInOrder'));
            }
          }
        },
        saveOrder() {

              let shipments = this.$refs.sheet.getShipments().serialize();
              let sToken = this.$store.state.sessionToken;
              this.saving = true;
              axios.put(`/api/advanceOrder/${this.seasonId}?session=${sToken}`, shipments)
                  .then(() => {
                      this.dirtyFlag = false;
                      EventBus.$emit('modal-requested', this.$t('advanceOrderForm.saveConfirm'));
                  })
                  .catch()
                  .finally(() => {
                      this.saving = false;
                  });


        },
        logout() {
            this.$store.dispatch('logout');
        },
        openTerms() {
          if(this.$i18n.locale === 'de-DE'){
            EventBus.$emit('modal-component-requested', Terms);
          }else{
            EventBus.$emit('modal-component-requested', TermsEN);
          }
        },
        openContacts() {
            EventBus.$emit('modal-component-requested', Contact);
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    @import "@/FrontEnd/styles/layout";
    h1 {
        text-align: center;
        margin: $slim-padding 0;
    }
    div.page-wrapper {
        height: 100%;
        box-sizing: border-box;
        padding: $regular-padding;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
    div.action-footer {
        @include footer-action-bar;
        &>.order-condition {
            margin: .2rem .3125rem;
        }
        &>.link-list {
            float: left;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            height: 100%;
        }
        &>.link-list li {
            margin: 0;
            padding: 0 .4rem;
            list-style: none;
            display: block;
            height: 100%;
            line-height: 2.6rem;

            &>a {
                vertical-align: middle;
            }
        }
    }
</style>