<template>
    <table cellspacing="0" cellpadding="0">
      <tr>
        <td>Terms and Conditions </td>
      </tr>
      <tr>
        <td> General Terms and Conditons of Sale, Deliverey and Payment of the Katadyn Deutschland GmbH </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>This text shall be governed by German law and construed in accordance with German legal understanding. It shall be issued in both German and English language. In case of deviations between the German and the English version, the German version shall take precedence.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td> § 1 General, Scope</td>
      </tr>
      <tr>
        <td>(1) The present General Terms and Conditions of Sale, Delivery and Payment („GSC“) shall apply to all business relations with our customers (hereinafter also referred to as „Buyer“). These GSC shall only apply if the Buyer is an entrepreneur (Section 14 German Civil Code (BGB)), a legal entity under public law or a public-law special fund.</td>
      </tr>
      <tr>
        <td>(2) These GSC shall apply in particular to contracts on the sale and/or delivery of movable properties (hereinafter also referred to as: „goods“), regardless if we produce those goods ourselves or purchase them from sub-suppliers (Sections 433, 651 German Civil Code). The General Sales Conditions as in effect from time to time shall also apply as framework agreement with the same Buyer for future contracts on the sale and/or delivery of movable properties, without any need of our express reference in each individual case.</td>
      </tr>
      <tr>
        <td>(3) Our GSC shall apply exclusively. Where the Buyer has his own different, conflicting or additional General Terms and Conditions, these shall only and to that extent form part of the contract, if we expressly agree on their validity in writing. This consent requirement shall apply in any event, e.g if we effect the delivery without reservation and in awareness of the Buyer‘s respective General Terms and Conditions.</td>
      </tr>
      <tr>
        <td>(4) Individual agreements with the Buyer made in a particular case (including supplementary agreements, amendments and modifications) shall in any case supersede these GSC. A contract in written form or our written confirmation shall be definitive for the content of such agreements.</td>
    </tr>
      <tr>
        <td>(5) Any legally relevant declarations and notifications towards us, which are to be made by the Buyer after the contract was concluded (e.g. deadlines, notice of defects, notice of repudiation of contract or price reduction), shall only be valid when issued in written form.</td>
      </tr>
      <tr>
        <td>(6) References to the application of statutory provisions only serve clarification purposes. Even without such a clarification, the statutory provisions shall be effective unless they are directly modified in these GSC or expressly excluded from them.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 2 Quotation; Conclusion of Contract</td>
      </tr>
      <tr>
        <td>(1) Our offers are non-binding and without obligation. This shall also apply for catalogs, price lists, technical documentations (e.g. drawings, schemes, calculations, costings, references to DIN standards) or other product descriptions and documents - also in electronic form - on which we reserve property rights and copyright and which we surrender to the Buyer. These documents shall only be made accessible to third parties upon our previous approval. The contained technical data (including indications of weight and dimensions) has been created thoroughly, errors excepted. The same shall apply to all data in our sales documents.</td>
      </tr>
      <tr>
        <td> (2) Packaging units and packaging sizes are non-binding and are updated by us regularly.</td>
      </tr>
      <tr>
        <td>(3) Data in statements, valid at the time of conclusion of the contract concerning scope of delivery, appearance, performance, dimension and weight etc. of the object of purchase do not form a promise of guarantee.</td>
      </tr>
      <tr>
        <td>(4) Orders placed with our field workers and agencies are only legally binding upon our confirmation.</td>
      </tr>
      <tr>
        <td>(5) The Buyer‘s placing of the order for the goods shall be regarded as a binding contractual offer. Unless stated otherwise in the order, we shall be entitled to accept this contractual offer within 2 weeks after receipt of the offer.</td>
      </tr>
      <tr>
        <td>(6) Acceptance shall either be declared in writing, by fax or by e-mail (e.g. by order confirmation) or through the supply of goods to the Buyer.</td>
      </tr>
      <tr>
        <td></td>
      </tr>

      <tr>
        <td>§ 3 Delivery Period, Delayed Delivery</td>
      </tr>
      <tr>
        <td>(1) The delivery period shall be agreed upon individually or stipulated by us upon acceptance of the order. As far as shipping was agreed, the delivery periods and delivery dates shall refer to the date of transfer to the forwarding agent, carrier or third parties commissioned with the transport. This shall not apply if an acceptance was stipulated contractually or if an installation obligation was agreed upon.</td>
      </tr>
      <tr>
        <td>(2) Unless stipulated otherwise, delivery periods shall begin with the date of order confirmation, however, not before the timely and proper performance of the Buyer‘s obligations, in particular not before the supplying of the documents, permissions and releases to be provided by the Buyer as well as not before the receipt of the stipulated deposit.</td>
      </tr>
      <tr>
        <td>(3) We are entitled to partial deliveries, if</td>
      </tr>
      <tr>
        <td>• the partial delivery is usable for the Buyer in the scope of the contractually intended use,</td>
      </tr>
      <tr>
        <td>• the delivery of the remaining ordered goods is ensured, and</td>
      </tr>
      <tr>
        <td>• no considerable extra effort or additional costs arise for the Buyer due to this (unless we agree to bear the costs).</td>
      </tr>
      <tr>
        <td>(4) We shall not be liable for impossibility of delivery or for delays in delivery as far as these were caused by force majeure or other unforeseeable incidents at the time of conclusion of the contract we are not responsible for (e.g. operating troubles of all kind, unavoidable scarcity of resources such as procurement of material and energy, labour conflicts, strikes, lock-outs, labour shortage, difficulties with obtaining necessary licenses, exceptional permissions or the missing, wrong or untimely delivery of goods by suppliers). As far as such incidents impede the fulfillment of delivery or services significantly or make it impossible, and those impediments are not only of temporary duration, we shall be entitled to withdraw from the contract. In the case of repudiation, we shall compensate the Buyer for any consideration that has already been provided by him.</td>
      </tr>
      <tr>
        <td>(5) In case of impediments of temporary duration, the delivery or service period shall be extended, or the delivery or service dates shall be postponed by the duration of the impediment plus an appropriate start-up period.</td>
      </tr>
      <tr>
        <td>(6) Insofar as the acceptance of delivery or service should be unreasonable for the Buyer due to the delay, he shall, by immediate written statement addressed to us, be entitled to withdraw from the contract.</td>
      </tr>
      <tr>
        <td>(7) Our legal rights of repudiation and termination as well as the statutory provisions on execution of contract under exclusion of the obligation to render services (e.g. impossibility or unreasonableness of the service and/or supplementary performance) shall remain unaffected. Furthermore, the Buyer‘s rights of repudiation and termination as per Section 8 of these GSC shall remain unaffected. Should we be in default with a delivery or performance, or should we be unable to deliver or perform, for whatever reason, our liability is restricted to compensation according to Section 8 of these GSC.</td>
      </tr>
      <tr>
        <td>(8) The occurrence of our default in delivery is determined by the statutory provisions. In any event, a reminder from the Buyer shall be required.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 4 Delivery, Transfer of Risks, Acceptance, Default of Acceptance</td>
      </tr>
      <tr>
        <td>(1) Goods are delivered ex works or ex warehouse, which shall also be the place of performance. At the Buyer‘s discretion and expense, the goods shall be shipped to a different place of destination (sale to destination). Unless stipulated otherwise, we shall have the right to determine the dispatch type ourselves (in particular shipping company, dispatch route, and packaging). On the Buyer‘s request, we insure dispatch on his expense against theft, breakage, transport, fire and water damages as well as against other insurable risks.</td>
      </tr>
      <tr>
        <td>(2) The risk of accidental destruction and accidental deterioration of the goods shall be passed on to the Buyer upon transfer at the latest. However, in case of sale to destination the risk of accidental destruction and accidental deterioration of the goods as well as the risk of delay shall already at the time of transfer pass on to the forwarding agent, carrier or other person or institutions commissioned with the execution of the dispatch. This shall apply also if carriage paid delivery is agreed upon. As far as an acceptance was agreed, the same shall be definitive for the transfer of risk.</td>
      </tr>
      <tr>
        <td>(3) If the Buyer is in default of acceptance, fails to co-operate or if our delivery is delayed due to other reasons attributable to the Buyer, we shall have the right to demand compensation for the damage arising from this including any additional expenses (e.g. storage costs). For this we shall charge a lump-sum compensation of 0.5 % (in words: zero point five per cent) of the net price per completed calendar week, starting with the delivery period or - if no delivery period was stipulated - with the notification of readiness for dispatch, on the whole, however, of not more than 5 % (in words: five per cent) of the net price of the goods the Buyer is in default of acceptance with. The right to prove of a greater loss and our legal claims (in particular from additional expenses, appropriate compensation, termination) shall remain unaffected; however, the lump sum shall be credited against further monetary claims. The Buyer shall reserve the right to prove that we had a considerably lower loss than above lump sum or no loss at all.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 5 Prices, Terms of Payment, Discounts, Minimum Quantities</td>
      </tr>
      <tr>
        <td>(1) The offered prices are valid only for the respective individual order. Price lists and other general price information are non-binding and are updated by us regularly.</td>
    </tr>
      <tr>
        <td>(2) Should delivery and/or service be carried out more than six (6) months after the date specified in the order confirmation,the Supplier shall be entitled to adjust prices, provided the list prices and/or material, labor or other costs have changed in the meantime. Fixed price agreements must be expressly agreed upon in writing.</td>
      </tr>
      <tr>
        <td>(3) Place of payment is our place of business. Unless stipulated otherwise in an individual case, our current prices at the time of contract conclusion shall apply, in fact ex works, including loading at the works excluding packaging, freight, insurance, custom duties, plus the respective applicable statutory value added tax (VAT).</td>
      </tr>
      <tr>
        <td>(4) In case of sale to destination (Section 4 paragraph 1 of these GSC), the Buyer shall bear the transport costs ex works and the costs for a possibly required transport insurance. The Buyer shall pay any arising customs duties, fees, taxes and other public charges. We shall not take back transport packaging and all other packaging according to the standards of the packaging regulation, they shall become property of the Buyer; pallets shall be excluded.</td>
      </tr>
      <tr>
        <td>(5) Unless otherwise agreed upon, the purchase price is due in full immediately and payable within 10 days from the date of the invoice and delivery or acceptance of the goods. In Case of Buyers, whose credit rankings are unknown to us or in case of doubt about the liquidity of the Buyer we shall deliver upon payment in advance or cash on delivery.</td>
      </tr>
      <tr>
        <td>(6) Upon expiry of above payment deadline, the Buyer shall be considered in default. During the default period, interests are to be paid on the purchase price as per legal default interest rate applicable from time to time. We reserve the right to claim further damages for default. Against merchants, our claim for the commercial interest after due date (Section 353 German Commercial Code) shall remain unaffected.</td>
      </tr>
      <tr>
        <td>(7) The Buyer shall only have the right to set-off or retention as far as his claim is determined in a legally binding judgment or undisputed. Further, the Buyer shall only be entitled to exercise a right of retention if his counterclaim applies to the same contractual relationship. The Buyer shall not be entitled to a right of retention due to partial performance according to Section 320 paragraph 2 German Civil Code. In case of defects of delivery, Section 7 paragraph 6 of these GSC shall remain unaffected.</td>
      </tr>
      <tr>
        <td>(8) Should it become apparent after conclusion of contract that our payment entitlements to the purchase price are jeopardized by the Buyer‘s inability to fulfill his obligations (e.g. by an application for insolvency), pursuant to statutory provisions, we shall have the right of refusal to perform and - after setting a deadline, if required - repudiation from the contract (Section 321 German Civil Code). For contracts concerning the manufacturing of specific goods (single-unit production), we reserve the right to withdraw immediately; the statutory provisions regarding the dispensability of a deadline shall remain unaffected.</td>
      </tr>
      <tr>
        <td>(9) Promissory notes or cheques shall only be accepted upon special agreement. The acceptance of promissory notes and cheques occurs only upon fulfillment. The Buyer shall bear the costs of discounting and collection. These costs are immediately payable. We shall be entitled to return any already accepted promissory notes if the Federal State Central Bank refuses to accept them.</td>
      </tr>
      <tr>
        <td>(10) Minimum value for orders and surcharges you will find in the effective price lists.</td>
      </tr>
      <tr>
        <td>(11) Prices stated by us in catalogues, brochures and price lists shall apply to dealers in the relation to their customers as recommended retail price.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 6 Retention of Title</td>
      </tr>
      <tr>
        <td>(1) We shall retain title to the sold goods (goods delivered under retention of title) until all outstanding accounts from the sales contract and the current business relation (secured claims) are paid in full.</td>
      </tr>
      <tr>
        <td>(2) If the Buyer acts in breach of contract, in particular in the case of payment default of an account receivable, we shall be entitled to reclaim the goods delivered under retention of title after the fruitless expiry of an appropriate payment deadline granted to the Buyer, or without such a deadline if it is dispensable according to statutory provisions. The Buyer shall bear the transport costs arising from reclaim. Insofar as we accept the goods delivered under retention of title, this shall be considered the repudiation of contract. Furthermore, it shall be considered a repudiation of contract if we seize the goods delivered under retention of title. We shall have the right to utilize any reclaimed goods delivered under retention of title. The return from utilization shall be offset with the Buyer‘s debts after we deducted a reasonable amount for the costs of utilization.</td>
      </tr>
      <tr>
        <td>(3) The Buyer shall handle the goods delivered under retention of title with due care. He shall be obliged especially to insure the goods delivered under retention of title at his own cost against damages due to fire, water and theft, to the sum of the value when new. If maintenance and inspection work is required, the Buyer shall carry it out in time at his own cost.</td>
      </tr>
      <tr>
        <td>(4) The Buyer shall be entitled to resell the goods under retention of title within the ordinary course of business unless he is not in default of payment. The goods under retention of title must not be pledged to third parties nor must the ownership be transferred as security until the secured claims are fully paid.</td>
      </tr>
      <tr>
        <td>(5) As a precaution, the Buyer shall hereby assign to us all claims against his customers arising from the resale of the goods delivered under retention of title to the full extent, as well as the those claims of the Buyer against his customers or third parties relating to the goods delivered under retention of title arising from any other legal reason (in particular claims arising from tort and entitlements to insurance payments). We accept such assignment. The Buyer may collect those claims assigned to us on his account and in his own name for us unless we revoke this authority. Our right to collect these claims ourselves shall remain unaffected by this; however, we shall not assert the claims ourselves nor revoke the direct debit authority as long as the Buyer meets his obligations for payment in a proper form. Should the Buyer act in breach of contract - in particular in the case of payment default of an account receivable - we may require the Buyer to inform us about the assigned claims and the respective debtors, to inform the respective debtors about the assignment, and to hand all documents over to us as well as provide all necessary information we require in order to assert the claim. The Buyer shall also not be authorized to assign these claims in order to have them collected by way of factoring unless he requires the factor irrevocably to provide consideration directly to us as long as we have still outstanding claims against the Buyer.</td>
      </tr>
      <tr>
        <td>(6) Any processing or modification of the goods delivered under retention of title by the Buyer shall always be carried out on our behalf. If the goods delivered under retention of title are processed with other goods which are not our property, we shall acquire co-title in proportion of the value of the goods delivered under retention of title (final amount of invoice plus any arising legal VAT as applicable from time to time) to that of the other processed goods at the time of processing. As for the rest, the resulting new product shall be subject to the same provisions applicable to the goods delivered under reservation of title. If the goods delivered under retention of title is inseparably combined or mixed with goods that are not our property, we shall acquire co-title in the new goods in proportion of the value of the goods delivered under retention of title (final amount of invoice plus any arising legal VAT as applicable from time to time) to that of the other combined or mixed good at the time of combination or mixture. If the goods delivered under retention of title are combined or mixed in such a way that the Buyer‘s good may be viewed as the main component, it shall hereby be agreed between us and the Buyer that he transfers us a proportionate co-title to this good. We accept such transfer. The Buyer shall keep the resulting sole or co-title to a good free of charge for us.</td>
      </tr>
      <tr>
        <td>(7) In case of seizures of the goods delivered under retention of title or other interventions by third parties, the Buyer shall be obliged to notify such party of our property and to inform us immediately in writing so that we can assert our title. Insofar as the third party is unable to reimburse to us the legal and out-of-court costs arising in this context, the Buyer shall be liable for this.</td>
      </tr>
      <tr>
        <td>(8) On the Buyer‘s discretion we shall be obliged to release the securities to which we are entitled to the extent their realizable value exceeds that of our outstanding claims against the Buyer by more than 10%. However, we shall select the securities to be released.</td>
      </tr>
      <tr>
        <td>(9) As far as the law covering the goods does not permit retention of title, Section 14 paragraph 1 sentence 2 to 4 of these GSC shall apply.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 7 Buyer’s Claims for Defects</td>
      </tr>
      <tr>
        <td>(1) Unless stipulated otherwise hereinafter, the statutory provisions shall apply to the Buyer‘s rights regarding defects of quality and title (including wrong and short delivery as well as incorrect installation or bad installation instructions).</td>
      </tr>
      <tr>
        <td>(2) If the Buyer is claimed against due to a defect of the newly produced goods in case of a final delivery of the goods to a consumer (recourse of the supplier as per Sections 478, 479 German Civil Code), the Buyer shall be obliged to inform us about this immediately. The Buyer shall oblige his customers correspondingly, as far as these are entrepreneurs. We reserve the right to fulfill the claims of the customer against the Buyer by adopting the transaction for ourselves. In this case, the fulfillment of the customer‘s claims shall be deemed the fulfillment of possible claims by the Buyer. As for the rest, the special statutory provisions for final delivery of the goods to the consumer (recourse of the supplier as per Sections 478, 479 German Civil Code) shall remain unaffected.</td>
      </tr>
      <tr>
        <td>(3) If the quality was not stipulated, it must be determined according to the statutory provision whether this is a case of defect or not (Section 434 paragraph 1 Sentence 2 and 3 German Civil Code). However, we shall not be liable for any public statements by the Manufacturer or other third parties (e.g. advertising messages).</td>
      </tr>
      <tr>
        <td>(4) The Buyer‘s claims for defects are subject to meeting his legal duty to examine and give notice of defects (Sections 377, 381 German Commercial Code). If a defect is detected during the examination or later, we must be notified about this immediately in writing. The notification shall be deemed made promptly when it is issued within eight (8) business days; the deadline shall be deemed adhered to when the notice is sent off in time. Regardless of this duty to examine and give notice of defects, the Buyer shall be obliged to report obvious defects (including wrong or short delivery) immediately in writing, whereby the deadline shall be deemed adhered to when the notice is sent off in time. If the Buyer fails to perform said proper examination and/or notification of defects, our liability shall be disclaimed for any defect that was not reported.</td>
      </tr>
      <tr>
        <td>(5) If the goods supplied are defective, we shall first select whether we provide supplementary performance by correcting the defect (rework) or by delivery of goods free from defects (replacement). Our right to refuse the selected type of supplementary performance under the legal requirements shall remain unaffected.</td>
      </tr>
      <tr>
        <td>(6) We shall be entitled to predicate the owed implementation of supplementary performance on the Buyer‘s payment of the outstanding purchase price. However, the Buyer shall have the right to retain an appropriate part of the purchase price in proportion to the defect.</td>
      </tr>
      <tr>
        <td>(7) The Buyer shall grant us the required time and opportunity to fulfill the owed supplementary performance; in particular he shall hand over the objected goods to us for inspection purposes. In the case of replacement, the Buyer shall return the defective goods to us according to the statutory regulations. Supplementary performance shall include neither the removal of the defective good nor the repeated installation, if we were not obliged to perform the installation originally.</td>
      </tr>
      <tr>
        <td>(8) The expenses for inspection purposes and supplementary performance, in particular costs for transportation, travel, labour and material (not: costs for installation and removal) shall be bourne by us in case of an existing defect. If the Buyer‘s claim for correction of defects should prove to be unjustified, we shall demand compensation from the Buyer for all costs incurred as a result of this. Replaced parts are our property and shall be returned to us.</td>
      </tr>
      <tr>
        <td>(9) If such supplementary performance should fail, or after an appropriate period for supplementary performance set by the Buyer has expired fruitlessly or is dispensable according to the statutory provisions, the Buyer shall be entitled to withdraw from the contract or to reduce the purchase price. If the defect is only insignificant, the Buyer shall not have the right to withdraw from the contract.</td>
      </tr>
      <tr>
        <td>(10) The Buyer‘s claims for compensation of damages or reimbursement of futile expenses shall only apply in accordance with Section 8 of these GSC and, as for the rest, be excluded.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 8 Other Liability</td>
      </tr>
      <tr>
        <td>(1) Unless stated otherwise in these GSC including the following provisions, we shall be liable for any breach of contractual and non-contractual duties according to the relevant statutory provisions.</td>
      </tr>
      <tr>
        <td>(2) We shall be liable for compensation - regardless for which legal reason - in cases of intent or gross negligence. In cases of ordinary negligence, we shall only be liable</td>
      </tr>
      <tr>
        <td>a) for damages arising from injury to life, body or health,</td>
      </tr>
      <tr>
        <td> b) for damages arising from violation of an essential contractual obligation (obligation, which makes the proper implementation of the contract possible at all and the adherence to which the contractual partner relies on and may rely on regularly); in this case, however, our liability shall be limited to the reimbursement of the foreseeable, typically occurring damage.</td>
      </tr>
      <tr>
        <td>(3) The limitations of liability as per paragraph 2 shall not apply if we maliciously concealed a defect or provided a guarantee for the condition of the goods. The same shall apply to the Buyer‘s claims according to the Product Liability Act.</td>
      </tr>
      <tr>
        <td>(4) The Buyer shall only have the right to withdraw or terminate due to a violation of duty which is not a defect if such violation of duty is attributable to us. An unrestricted right of termination on part of the Buyer (in particular according to Sections 651, 649 German Civil Code) shall be excluded. As for the rest, the legal requirements and legal consequences shall apply.</td>
      </tr>
      <tr>
        <td>(5) If our liability is disclaimed or limited, this shall apply to the same extent to the benefit of our institutions, legal representatives, employees and other vicarious agents.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 9 Obligations as per Electrical and Electronic Equipment Act (ElektroG)</td>
      </tr>
      <tr>
        <td>(1) The Buyer shall assume the obligation to properly dispose of the supplied goods after the end of their usage at his own expense and according to the statutory provisions, and thus he shall release us from any obligations as per Section 10 paragraph 2 Electrical and Electronic Equipment Act - Manufacturers‘ obligation to take back the WEEE (waste electrical and electronic equipment) - and any claims of third parties in connection with this.</td>
      </tr>
      <tr>
        <td>(2) If the Buyer passes on the goods to commercial third parties and omits to oblige them contractually to take over the disposal and to impose a disposal obligation to any further party the goods are passed on later, the Buyer shall take back the supplied goods after the end of their usage at his expense and dispose of them properly according to the statutory provisions.</td>
      </tr>
      <tr>
        <td>(3) Our claim for take-over / indemnity by the Buyer shall not become statute-barred before expiry of a period of two years after the final end of use of the device. This period shall start at the earliest upon our receipt of a written notice by the Buyer about the end of usage.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 10 Limitation</td>
      </tr>
      <tr>
        <td>(1) As an exception to Section 438 paragraph 1 Number 3 German Civil Code, the general period of limitation for claims for defects of quality and title shall be one year starting with the delivery of the goods. As far as an acceptance was agreed, limitation of actions shall commence with the acceptance.</td>
      </tr>
      <tr>
        <td>(2) Special statutory provisions for material claims for surrender of third parties (Section 438 paragraph 1 Number 1 German Civil Code), for the case that the Seller maliciously concealed the defect (Section 438 paragraph 3 German Civil Code) and for claims of the supplier‘s recourse in case of final delivery to a consumer (Section 479 German Civil Code) shall remain unaffected.</td>
      </tr>
      <tr>
        <td>(3) The above limitation periods for sale of goods law shall also apply to the Buyer‘s contractual and non-contractual compensation for damages based on a defect of the goods; unless the application of the regular statutory limitation period (Sections 195, 199 German Civil Code) results in a shorter limitation period in an individual case. The limitation periods of the Product Liability Act shall remain unaffected in any case. As for the rest, the statutory limitation periods shall apply exclusively to the Buyer‘s claims for compensation of damages according to Section 8 of these GSC.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 11 Industrial Property Rights</td>
      </tr>
      <tr>
        <td>(1) The Buyer undertakes to comply with existing copyrights and other intellectual property rights of the supplied goods and the associated documentation.</td>
      </tr>
      <tr>
        <td>(2) If the supplied goods are designed according to the Buyer‘s drawings, descriptions or samples, the Buyer shall furnish a guarantee that our manufacturing and delivery of the planned design will not violate any copyrights or other intellectual property rights of third parties.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 12 Secrecy</td>
      </tr>
      <tr>
        <td>The Buyer undertakes to maintain confidentiality, even after termination of contract, on company and business secrets, which he may have got to know in the course of the business relation or the contract. The duty of secrecy shall only expire when and as far as this knowledge is in the public domain or was already known to the Buyer at the time of conclusion of contract, without any breach of contract by the Buyer being the cause.
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 13 Data Protection</td>
      </tr>
      <tr>
        <td> With regard to the Buyer's personal data, we observe the relevant legal provisions, in particular the General Data Protection Regulation (GSPR). We only use the personal data stored by us for our own purposes and do not pass them on to external third parties unless there is an officially ordered obligation to do so or the Buyer has not expressly given his consent. This regulation on the handling of personal data is specified and supplemented by the Data Protection Note and the Information Sheet for Customers and Suppliers.</td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td>§ 14 Choice of Law, Place of Jurisdiction</td>
      </tr>
      <tr>
        <td>(1) The law of the Federal Republic of Germany shall apply to these GSC and all legal relations between us and the Buyer; all international and supranational (contractual) legal orders, especially the United Nations Convention on Contracts for the International Sale of Goods (CISG) shall be excluded. Prerequisites and effects of reservation of title according to Section 6 of these GSC shall, however, be subject to the law of the respective location of the property, as far as the choice of law is impermissible or ineffective in favor of German law. As far as the law covering the goods does not permit retention of title, we shall be entitled to make use of all rights to reserve the goods. The Buyer is obliged to support us with all measures for protection of our right of ownership or instead another security right to the goods.
        </td>
      </tr>
      <tr>
        <td>(2) Insofar as the Buyer is a merchant within the meaning of the German Commercial Code, a legal entity under public law or a public-law special fund, the exclusive - and international - place of jurisdiction for all disputes arising directly or indirectly from and in connection with the contractual relationship shall be our registered office in Mörfelden-Waldorf/Germany. However, we shall also be entitled to take legal action at the Buyer‘s general place of jurisdiction.
        </td>
      </tr>
      <tr>
        <td>(3) The remaining provisions will remain unaffected and thus, Section 139 German Civil Code is waived if one of the provisions of these GSC or a provision added later is deemed completely or partially invalid or should become invalid, or if a provision of these GSC should be found to contain a void. The invalid provision or the void shall be replaced by a valid and practicable provision the legal and economic effect of which comes as close as possible to what the parties intended or would have intended in accordance with the meaning and purpose of this contract, if they had considered this at the time of the conclusion of the contract. Should the invalidity of a provision be based on a scope of performance or time (period of time or date), the provision shall be agreed on with a legally valid scope which comes as close as possible to the original scope.
        </td>
      </tr>
    </table>
  <!--









    § 14 Choice of Law, Place of Jurisdiction
    (1) The law of the Federal Republic of Germany shall apply to these GSC and all legal relations between us and the Buyer; all international and supranational (contractual) legal orders, especially the United Nations Convention on Contracts for the International Sale of Goods (CISG) shall be excluded. Prerequisites and effects of reservation of title according to Section 6 of these GSC shall, however, be subject to the law of the respective location of the property, as far as the choice of law is impermissible or ineffective in favor of German law. As far as the law covering the goods does not permit retention of title, we shall be entitled to make use of all rights to reserve the goods. The Buyer is obliged to support us with all measures for protection of our right of ownership or instead another security right to the goods.
    (2) Insofar as the Buyer is a merchant within the meaning of the German Commercial Code, a legal entity under public law or a public-law special fund, the exclusive - and international - place of jurisdiction for all disputes arising directly or indirectly from and in connection with the contractual relationship shall be our registered office in Mörfelden-Waldorf/Germany. However, we shall also be entitled to take legal action at the Buyer‘s general place of jurisdiction.
    (3) The remaining provisions will remain unaffected and thus, Section 139 German Civil Code is waived if one of the provisions of these GSC or a provision added later is deemed completely or partially invalid or should become invalid, or if a provision of these GSC should be found to contain a void. The invalid provision or the void shall be replaced by a valid and practicable provision the legal and economic effect of which comes as close as possible to what the parties intended or would have intended in accordance with the meaning and purpose of this contract, if they had considered this at the time of the conclusion of the contract. Should the invalidity of a provision be based on a scope of performance or time (period of time or date), the provision shall be agreed on with a legally valid scope which comes as close as possible to the original scope.
  -->
  </template>
<script>
export default {
name: "TermsEN"
}
</script>

<style scoped>

</style>