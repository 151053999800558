<template>

</template>

<script>
export default {
  name: "DiscountScaleQuantity"
}
</script>

<style scoped>

</style><template>
  <div class="discount-scale-wrapper">
    <label>{{ $t('discountScale.title') }}</label>
    <fieldset v-for="(discountLevel, index) in discounts" :key="index">
      <div>
        <label>{{ $t('discountScale.quantity.minNetSum') }}</label>
        <input type="number" v-model="discountLevel.min" class="slim">
      </div>
      <div>
        <label>{{ $t('discountScale.percentage') }}</label>
        <input type="number" v-model="discountLevel.percent" class="slim">
      </div>
      <button @click="deleteLevel(index)" class="slim" :title="$t('discountScale.removeLevel')">
        <fa-icon icon="minus"></fa-icon>
      </button>
    </fieldset>
    <button @click="addLevel" class="slim"><fa-icon icon="plus"></fa-icon> {{ $t('discountScale.addDiscountLevel') }}</button>
  </div>
</template>

<script>
export default {
  name: "DiscountScaleQuantity",
  props: {
    value: Array,
    type: String
  },
  computed: {
    discounts: {
      get() {
        return this.value
      },
      set(newval) {
        this.$emit('input', newval);
      }
    },
    notLast() {
      return this.discounts.length !== 1;
    }
  },
  methods: {
    addLevel() {
      this.discounts.push({min:1000, percent:1});
    },
    deleteLevel(index) {
      this.discounts.splice(index, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/FrontEnd/styles/input";
@import "@/FrontEnd/styles/layout";

.discount-scale-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  &>label {
    margin-bottom: .4rem;
  }

  &>fieldset {
    border: none;
    padding: 0;
    margin-bottom: .8rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &>div {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      &>label {
        margin-right: .4rem;
        max-width: 2rem;
        min-width: 8rem;
      }
    }

    &>*{
      margin: 0 .4rem;
    }
  }

  .addButton{
    border-radius: 50%;
    border:none;
    background: $brand-color;
    color:white;
    align-self: center;
    line-height: 2.25rem;
    height:2.35rem;

  }

  .deleteButton{
    border-radius: 50%;
    border:none;
    background: linear-gradient(#565656 0%, #444 69%);
    color:white;
    line-height: 2.25rem;
    height:2.35rem;
  }
}
</style>