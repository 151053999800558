<template>
    <div class="discount-info-wrapper">
        <div class="discount-info-tabs">
            <nav>
                <li v-if="hasDeadlines">
                    <a href="#" :class="{active: infoTab === 'deadline'}" @click.prevent="infoTab = 'deadline'">
                        {{ $t('discountInfo.deadlineTitle') }}
                    </a>
                </li>
                <li v-if="hasDiscountScale">
                    <a href="#" :class="{active: infoTab === 'scale'}" @click.prevent="infoTab = 'scale'">
                        {{ $t('discountInfo.discountTitle') }}
                    </a>
                </li>
                <li v-if="hasShipmentDiscount">
                  <a href="#" :class="{active: infoTab === 'shipment'}" @click.prevent="infoTab = 'shipment'">
                    {{ $t('discountInfo.shipmentDiscountTitle') }}
                  </a>
                </li>
                <li v-if="hasVolumeDiscount">
                    <a href="#" :class="{active: infoTab === 'volume'}" @click.prevent="infoTab = 'volume'">
                        {{ $t('discountInfo.volumeInfoTitle') }}
                    </a>
                </li>
                <li v-if="hasBaseDiscount">
                    <a href="#" :class="{active: infoTab === 'base'}" @click.prevent="infoTab = 'base'">
                        {{ $t('discountInfo.baseInfoTitle') }}
                    </a>
                </li>
            </nav>
            <aside v-if="hasDeadlines && infoTab === 'deadline'">
                <table>
                    <thead>
                        <tr>
                            <th>{{ $t('deadlineInfo.orderDeadline') }}</th>
                            <th>{{ $t('deadlineInfo.firstShipment') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(deadline, index) in deadlines" :key="index">
                            <td>{{ $d(deadline.date, 'short') }}</td>
                            <td>{{ $d(deadline.startDate, 'short') }}</td>
                        </tr>
                    </tbody>
                </table>
            </aside>
            <aside v-if="hasDiscountScale && infoTab === 'scale'">
                <p>{{ $t('discountInfo.discountInfo') }} <br>
                  <b>{{ $t('discountInfo.discountInfoAdditional') }}</b></p>

                <table class="discount-scale-table">
                    <tbody>
                        <tr v-for="discount in discountScale" :key="discount.min">
                            <td v-if="discount.type === 'quantity'">
                                {{ $t('discountInfo.discountQuantityText', {min:$n(discount.min), percent:discount.percent}) }}
                            </td>
                            <td v-if="discount.type === 'amount'">
                              {{ $t('discountInfo.discountAmountText', {min:$n(discount.min,'currency'), percent:discount.percent}) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </aside>
            <aside v-if="hasDiscountScale && infoTab === 'shipment'">
              <p>{{ $t('discountInfo.shipmentDiscountInfo') }} <br>
               {{ $t('discountInfo.shipmentDiscountInfoAdditional') }}</p>
              <p> <b>{{ $t('discountInfo.shipmentDiscountShipmentAmountText', {min:$n(shipmentDiscount.min), percent:shipmentDiscount.percentage,count:shipmentDiscount.count}) }}</b></p>
            </aside>
            <aside v-if="hasVolumeDiscount" v-show="infoTab === 'volume'">
                <p>{{ $t('discountInfo.volumeInfoDesc') }}</p>
                <volume-discount-info
                    v-if="shipments !== null"
                    :shipments="shipments"
                    :articles="articles"
                    :total-net-price="totalNetPrice"
                    :volume-discounts="volumeDiscounts"
                    @discount-volume-changed="handleVolumeDiscountChange"
                ></volume-discount-info>
            </aside>
            <aside v-if="hasBaseDiscount && infoTab === 'base'">
                <p>
                    {{ $t('discountInfo.baseInfoDesc', this.baseDiscount) }}
                </p>
            </aside>
        </div>
        <div class="discount-calc-table">
            <table>
                <tbody>
                <tr>
                    <td><!--{{ $t('discountInfo.totalNetPrice') }}--></td>
                    <td ></td>
                    <td><!--{{ totalNetPriceFormatted}}--></td>
                </tr>
                <!--<tr v-if="hasShipmentBonus" style="text-align:right">
                  <td>{{ $t('discountInfo.shipmentDiscount') }}</td>
                  <td>{{ shipmentDiscountPercentage }}%</td>
                  <td>{{ shipmentDiscountAmountFormatted }}</td>
                </tr>-->
                <!--<tr v-if="hasDiscountScale">
                    <td>{{ $t('discountInfo.appliedStaggeredDiscount') }}</td>
                    <td>{{ staggeredDiscountPercentage }}%</td>
                    <td>{{ $n(staggeredDiscountAmount, 'currency') }}</td>
                </tr>-->
                <tr v-if="hasBaseDiscount">
                    <td>{{ $t('discountInfo.appliedBaseDiscount') }}</td>
                    <td>{{ baseDiscountPercentage }}%</td>
                    <td>{{ $n(baseDiscountAmount, 'currency') }}</td>
                </tr>
                <tr v-if="hasVolumeDiscount">
                    <td>{{ $t('discountInfo.appliedVolumeDiscount') }}</td>
                    <td>{{ volumeDiscountPercentage }}%</td>
                    <td>{{ $n(volumeDiscountAmount, 'currency') }}</td>
                </tr>
                <tr>
                    <td>{{ $t('discountInfo.discountedNetPrice') }}</td>
                    <td></td>
                    <td>{{ totalNet }}</td>
                </tr>
                </tbody>
            </table>
            <span v-if="hasMinOrderAmount"><b>{{ $t('discountInfo.minOrderAmount',{amount:minOrderAmountFormatted}) }}</b></span>
          <span v-else-if="hasMinShipmentAmount"><b>{{ $t('discountInfo.minShipmentAmount',{amount:minOrderAmountFormatted}) }}</b></span>
            <span>{{ $t('discountInfo.disclaimer') }}</span>
        </div>
    </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import VolumeDiscountInfo from "@/FrontEnd/components/VolumeDiscountInfo";

export default {
    name: "DiscountInfo",
    components: {VolumeDiscountInfo},
    props: {
        minOrderAmount:{
          type: Object
        },
        shipmentDiscount:{
          type: Object
        },
        discountScale: {
            type: Array
          },
          baseDiscount: {
            type: Object,
            default: null
          },
          volumeDiscounts: {
            type: Array,
            default: null
          },
          amountDiscounts: {
            type: Array,
          default: null
        },
        periodMode: {
            type: Boolean,
            default: false
        },
        articles: Array,
        shipments: ShipmentDates,
        deadlines: Array,
    },
    data () {
        return {
            infoTab: null,
            volumeDiscountApplies: false
        };
    },
    created() {
        switch (true) {
            case this.hasDeadlines:
                this.infoTab = 'deadline';
                break;
            case this.hasDiscountScale:
                this.infoTab = 'scale';
                break;
            case this.hasShipmentDiscount:
              this.infoTab = 'shipment';
              break;
            case this.hasVolumeDiscount:
                this.infoTab = 'volume';
                break;
          case this.hasAmountDiscount:
              this.infoTab = 'quantity';
              break;
            case this.hasBaseDiscount:
                this.infoTab = 'base';
                break;
        }

    },
    computed: {
      formatter(){
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol'})
      },
      totalNet() {
        return this.formatPrice(this.shipments.getTotalNetPriceFromShipments());
      },

      minOrderAmountFormatted(){
          return this.formatPrice(this.minOrderAmount.minAmount);
      } ,
      shipmentDiscountAmountFormatted(){
        return this.formatPrice(this.shipmentDiscountAmount);
      },
      shipmentDiscountAmount(){


        return this.totalNetPrice * (this.shipmentDiscountPercentage/100);
      },
        totalNetPriceFormatted() {
          return this.formatPrice(this.totalNetPrice);
      },
        totalNetPrice() {
            return this.shipments.getTotalNetPriceFromShipments();
        },
        totalQuantity(){

          return this.shipments.getTotalQuantity(this.articles);
        },
        hasDeadlines() {
            return this.deadlines !== null && this.deadlines.length > 0 && this.periodMode === false;
        },
        hasDiscountScale() {
          return this.discountScale !== null && this.discountScale.length > 0

        },
        hasBaseDiscount() {
            return this.baseDiscount !== null;
        },
        hasVolumeDiscount() {
            return this.volumeDiscounts !== null
                && this.volumeDiscounts.length > 0;
        },
        hasShipmentDiscount(){
            return this.shipmentDiscount.active;
        },
        hasMinShipmentAmount(){
          return this.minOrderAmount.perShipment && this.minOrderAmount.minAmount > 0;
        },
        hasMinOrderAmount(){
            return !this.minOrderAmount.perShipment && this.minOrderAmount.minAmount > 0;
        },
        hasShipmentBonus(){
            return true;
        },
        hasAmountDiscount(){
            return false;
        },
        shipmentDiscountPercentage(){

            if(this.shipmentDiscount){
              let validShipmentCount = 0;

              for(let shipment of this.shipments){
                if(shipment.totalNet >= this.shipmentDiscount.min){
                  validShipmentCount += 1;
                }
              }
              if(validShipmentCount >= this.shipmentDiscount.count){
                return this.shipmentDiscount.percentage;
              }
            }

            return 0;

        },
        baseDiscountPercentage() {
            if (this.hasBaseDiscount && this.totalNetPrice >= this.baseDiscount.min) {
                return this.baseDiscount.discount;
            }
            return 0;
        },
        staggeredDiscountPercentage() {
            let discounts = JSON.parse(JSON.stringify(this.discountScale));

            let type = discounts[0].type;

            if(type === 'quantity'){
              for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
                if (this.totalQuantity >= discount.min) {
                  return discount.percent;
                }
              }
            }else{
              for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
                if (this.totalNetPrice >= discount.min) {
                  return discount.percent;
                }
              }
            }


            return 0;
        },

        volumeDiscountPercentage() {
            if (!this.hasVolumeDiscount || this.shipments === null || !this.volumeDiscountApplies) {
                return 0;
            }
            return 2;
        },
        staggeredDiscountAmount() {
            return this.totalNetPrice * (this.staggeredDiscountPercentage / 100);
        },
        baseDiscountAmount() {
            return this.totalNetPrice * (this.baseDiscountPercentage / 100);
        },
        volumeDiscountAmount() {
            return this.totalNetPrice * (this.volumeDiscountPercentage / 100);
        },
        finalNetPrice() {
            return (
                this.totalNetPrice - this.staggeredDiscountAmount - this.baseDiscountAmount - this.volumeDiscountAmount
            );
        },
        flatArticleIds() {
            let articles = JSON.parse(JSON.stringify(this.articles));
            return articles.reduce((flatArticles, brand) => flatArticles.concat(brand.articles), []);
        }
    },
    methods: {
      checkAmountsForVEAndMOQ(){

      },
      formatPrice(price){
        return this.formatter.format(price);
      },
        handleVolumeDiscountChange({applies}) {
            this.volumeDiscountApplies = applies;
        }
    }
}
</script>

<style scoped lang="scss">
    .discount-info-wrapper {
        margin-top: .4rem;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-items: flex-end;
        flex-wrap: wrap;

        &>.discount-info-tabs {
            flex-grow: 1;
            width: 60%;
            min-width: 40rem;

            &>nav {
                display: flex;
                flex-direction: row;
                padding: 0;
                margin: 0;

                &>li {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    width: auto;

                    &>a {
                        position: relative;
                        display: block;
                        padding: .6rem;
                        border: .1rem solid #dddddd;
                        border-bottom: none;
                        width: auto;
                        text-decoration: none;
                        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
                        z-index: 0;


                        &:active, &:visited, &:focus {
                            text-decoration: none;
                            color: currentColor;
                        }
                        &:hover {
                            color: #004489;
                            background: #fff;
                        }
                        &.active {
                            background: #fff;
                            z-index: 5;
                            &:hover {
                                color: currentColor;
                            }
                        }
                    }
                }
            }
            &>aside {
                position: relative;
                margin-top: -.1rem;
                box-sizing: border-box;
                border: .1rem solid #dddddd;
                padding: .4rem;
                background: #fff;
                z-index: 3;
            }
        }
        &>.discount-calc-table {
            flex-grow: 1;
            width: 40%;
            min-width: 30rem;
            box-sizing: border-box;
            padding: .4rem;

            & span {
                display: block;
                text-align: left;
                margin: .4rem 0;
            }
        }
        .discount-scale-table {
            margin: 0 auto;
            width: auto;
        }
    }
</style>