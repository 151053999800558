<template>
  <tfoot>
  <!--<tr v-if="hiddenAmountHint">
      <td class="hint" :colspan="calculateColSpan()">
          {{ $t('article.amountHidden') }}
          <a href="#" @click.prevent="$emit('reset-filter-and-show-amounts')">
              {{ $t('article.showHiddenAmounts') }}
          </a>
      </td>
  </tr>-->
  <tr>

  </tr>
  <tr v-if="allowCustomOrderNumber">
    <td colspan="9" class="customer-order-numbers">{{ $t('article.customerOrderNumber') }}</td>
    <td v-for="date in shipmentDates.dates" :key="date.id" class="customer-order-numbers">
      <input
          v-if="date.status"
          type="text"
          class="slim"
          :placeholder="$t('article.customerOrderNumberLabel')"
          :title="$t('article.customerOrderNumberTitle', {date: $d(date.date, 'short')})"
          v-model="date.orderNumber"
      >
    </td>
    <td colspan="2" class="customer-order-numbers"></td>
  </tr>
  <tr style="text-align: right;bottom:2.5rem">
    <td colspan="9" class="total"  >
      {{ $t('shipmentCalculation.shipmentTotal') }}
    </td>
    <td v-for="shipment in shipmentDates.dates" class="total">
      <OrderSheetShipmentTotal :articles="articles" :shipments="shipmentDates" :shipmentId="shipment.id"> </OrderSheetShipmentTotal>
    </td>
    <td colspan="2" class="total"></td>

  </tr>
  <!--<tr style="text-align: right;">
    <td colspan="10" class="minOrderAmount"  >
      {{ $t('shipmentCalculation.shipmentMinOrderAmount') }}
    </td>
    <td v-for="shipment in shipmentDates.dates" class="minOrderAmount">
      <OrderSheetShipmentTotal :shipments="shipmentDates" :shipmentId="shipment.id"> </OrderSheetShipmentTotal>
    </td>
    <td colspan="2" class="minOrderAmount"></td>
  </tr>-->



  <!--<tr>
    <td colspan="10" class="total-amount" style="text-align: right">
      {{ $t('shipmentCalculation.shipmentTotal') }}
    </td>
    <td
        v-for="date in shipmentDates.dates"
        :key="date"
        class="total-amount number">
      <OrderSheetShipmentCalculation
          :discount-scale="discountScale"
          :base-discount="baseDiscount"
          :shipment="date"
          :volume-discounts="volumeDiscounts"
          :articles="brands"
          :deadlines="deadlines"
          :period-mode="periodMode"
      ></OrderSheetShipmentCalculation>
    </td>
    <td class="total-amount number" :colspan="2">
          <span v-if="showTotals">
              {{ $t('article.sheetTotalNetPrice') }} {{ $n(totalNetPrice, 'currency') }}
          </span>
    </td>
  </tr>-->
  </tfoot>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import OrderSheetShipmentCalculation from "@/FrontEnd/components/OrderSheetShipmentCalculation";
import Test from "@/FrontEnd/components/Test";
import OrderSheetShipmentSum from "@/FrontEnd/components/OrderSheetShipmentSum";
import OrderSheetShipmentStaggeredDiscount from "@/FrontEnd/components/OrderSheetShipmentStaggeredDiscount";
import OrderSheetShipmentTotal from "@/FrontEnd/components/OrderSheetShipmentTotal";
import OrderSheetShipmentTotalSum from "@/FrontEnd/components/OrderSheetShipmentTotalSum";
export default {
  name: "A",
  components: {OrderSheetShipmentStaggeredDiscount, OrderSheetShipmentSum, OrderSheetShipmentCalculation,OrderSheetShipmentTotal,OrderSheetShipmentTotalSum},
  data (){
    return {

    }
  },
  props: {
    pricegroup:{
      type: String,
      default: 'EH'
    },
    discountScale: {
      type: Array
    },
    baseDiscount: {
      type: Object,
      default: null
    },
    shipmentDiscount: {
      type: Object
    },
    periodMode:{
      type:Boolean
    },
    volumeDiscounts: {
      type: Array,
      default: null
    },
    amountDiscounts: {
      type: Array,
      default: null
    },
    articles: {
      type: Array
    },
    deadlines:{
      type: Array
    },
    shipmentDates: ShipmentDates,
    showTotals: Boolean,
    hiddenAmountHint: Boolean,
    brands: Array,
    allowCustomOrderNumber: {
      type: Boolean,
      default: false
    }
  },
  created(){

  },
  computed: {
    hasShipmentDiscount(){
      //return this.shipmentDiscount.active;
      return false;
    },
    hasStaggeredDiscounts(){
      return false;
      //return this.discountScale !== null && this.discountScale.length > 0;

    },

    totalNetPrice(shipment) {

      return 0;
    }




  },
  methods: {
    calculateShipmentAmountSum(shipmentId){

      return this.shipmentDates.getShipmentById(shipmentId).getTotalNetPrice(this.articles);

      /*for (let brand of this.articles) {
        for (let article of brand.articles) {
          if(!(articleId in this.articleAmounts)) {
            return 0;
          }
          return this.articleAmounts[articleId];
          total += (this.getAmount(article.id) * article.netPrice);
        }
      }
      return total;

      if(!(articleId in this.articleAmounts)) {
        return 0;
      }
      return this.articleAmounts[articleId];*/

    },
    calculateColSpan() {
      return 9 + this.shipmentDates.getAmountOfDates() + (this.shipmentDates.getAmountOfDates() > 1 ? 1 : 0);
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/table";
@import "@/FrontEnd/styles/input";

.discount-info-wrapper {
  margin-top: .4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-items: flex-end;
  flex-wrap: wrap;

  &>.discount-info-tabs {
    flex-grow: 1;
    width: 60%;
    min-width: 40rem;

    &>nav {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0;

      &>li {
        margin: 0;
        padding: 0;
        list-style: none;
        width: auto;

        &>a {
          position: relative;
          display: block;
          padding: .6rem;
          border: .1rem solid #dddddd;
          border-bottom: none;
          width: auto;
          text-decoration: none;
          background: linear-gradient(to bottom, #fff 0%, #eee 100%);
          z-index: 0;


          &:active, &:visited, &:focus {
            text-decoration: none;
            color: currentColor;
          }
          &:hover {
            color: #004489;
            background: #fff;
          }
          &.active {
            background: #fff;
            z-index: 5;
            &:hover {
              color: currentColor;
            }
          }
        }
      }
    }
    &>aside {
      position: relative;
      margin-top: -.1rem;
      box-sizing: border-box;
      border: .1rem solid #dddddd;
      padding: .4rem;
      background: #fff;
      z-index: 3;
    }
  }
  &>.discount-calc-table {
    flex-grow: 1;
    width: 40%;
    min-width: 30rem;
    box-sizing: border-box;
    padding: .4rem;

    & span {
      display: block;
      text-align: left;
      margin: .4rem 0;
    }
  }
  .discount-scale-table {
    margin: 0 auto;
    width: auto;
  }
}
tfoot td, tfoot th {
  background-color: $main-bg-color;

  & input[type=text] {
    width: 7.2rem;
  }

  &.total-amount{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    height: 2.8rem;
    box-sizing: border-box;

    & .price-notice {
      float: left;
    }
  }
  &.hint, &.customer-order-numbers {
    position: -webkit-sticky;
    position: sticky;
    bottom: 2.5rem;

    &.hint {
      color: crimson;
    }
  }
  &.total {
    text-align: right;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

    &.hint {
      color: crimson;
    }
  }
  &.sum {
    text-align: right;
    position: -webkit-sticky;
    position: sticky;
    bottom: 4rem;

    &.hint {
      color: crimson;
    }
  }
  &.staggered {
    text-align: right;
    position: -webkit-sticky;
    position: sticky;
    bottom: 2.5rem;

    &.hint {
      color: crimson;
    }
  }
  &.bonus {
    text-align: right;
    position: -webkit-sticky;
    position: sticky;
    bottom: 4rem;

    &.hint {
      color: crimson;
    }
  }
  &.minOrderAmount {
    text-align: right;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

    &.hint {
      color: crimson;
    }
  }

  &.customer-order-numbers {
    text-align: right;
  }
}
</style>