<template>
  <page-with-navigation>
    <div class="actions">
      <button class="primary" @click="$router.push({name: 'edit-season'})">
        <fa-icon icon="plus"></fa-icon>
        {{ $t('orderSeason.new') }}
      </button>
    </div>
    <order-season-list></order-season-list>
  </page-with-navigation>
</template>

<script>
import PageWithNavigation from "@/FrontEnd/components/PageWithNavigation";
import OrderSeasonList from "@/FrontEnd/components/OrderSeasonList";
export default {
  name: "OrderSeasonOverview",
  components: {OrderSeasonList, PageWithNavigation}
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/input";
div.actions {
  width: 100%;
  text-align: right;
}
</style>