const messages = {
    'de-DE': {
        shipmentHeader:{
            orderstop: 'Orderdeadline: ',
            shipmentPeriod : 'Lieferfenster'
        },
        shipmentCalculation:{
            shipmentSum: 'Zwischensumme',
            staggeredDiscount:'Vororderrabatt',
            shipmentCountDiscount: 'Sonderrabatt',
            shipmentTotal: 'Summe Lieferfenster',
            shipmentMinOrderAmount: 'Mindestbestellwert'

        },
        article: {
            nr: 'Artikelnummer',
            'lang-cluster': 'Sprach-cluster',
            description: 'Beschreibung',
            color: 'Farbe',
            info: 'Info',
            moq: 'MOQ',
            ve: 'VE-Order',
            'casepack-amount': 'Menge Casepack',
            gtin: 'GTIN13 / EAN',
            'net-price': 'Preis netto',
            'retail-price':'Einzelhändler Preis netto in EUR',
            'retail-rrp':'UVP brutto in EUR',
            'distributor-price': 'Distributor Price excl. VAT in EUR',
            'distributor-rrp': 'Sug. Retail Price excl. VAT in EUR',
            'recommended-list-price': 'UVP brutto',
            'amount': 'Menge',
            'total-amount': 'Menge Gesamt',
            'total-amount-brand': 'Total',
            'total-net-price': 'EK Gesamt',
            sheetTotalNetPrice: 'Gesamt',
            priceNotice: 'Abgebildete Preise sind Einzelhandelspreise',
            emptyFilterResult: 'Es gibt keine passenden Ergebnisse. Überprüfen Sie die Filter-Kriterien.',
            amountHidden: 'Artikel mit Mengen sind ausgeblendet.',
            invalidAmount: 'Beachten Sie die Mindestmenge von {min} und die Staffel von {step}',
            showHiddenAmounts: 'anzeigen',
            customerOrderNumber: 'eigene Bestellnummer',
            customerOrderNumberLabel: 'Bestellnr.',
            customerOrderNumberTitle: 'Bestellnr. für {date}'
        },
        orderSheet: {
            hideEmptyPos: 'Nur Positionen mit Menge anzeigen',
            search: 'Artikel nach Nr, Name, Marke filtern'
        },
        orderSentForm: {
            update: 'Änderungen speichern'
        },
        orderRecForm: {
            name: 'Angebotsname',
            customer: 'Kunde',
            message: 'Angebotstext',
            send: 'Senden',
            save: 'Als Vorlage speichern',
            update: 'Vorlage speichern',
            customerMissing: 'Bitte einen Kunden auswählen',
            nameMissing: 'Bitte einen Namen vergeben',
            confirm: 'Angebot an Kunden senden',
            confirmTitle: 'Angebot bestätigen',
            title: 'Angebot',
            sentTitle: 'Angebot',
            cancel: 'Abbrechen',
            back: 'Zurück zur Übersicht',
            exportPDF: 'Als PDF exportieren',
            sendSuccess: 'Das Angebot wurde erfolgreich gesendet.',
            saveSuccess: 'Die Vorlage wurde erfolgreich gespeichert.',
            updateSentSuccess: 'Die Änderungen am Angebot wurden erfolgreich gespeichert.',
            updateSuccess: 'Die Änderungen an der Vorlage wurden erfolgreich gespeichert.',
            unknownError: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später nochmal.',
            resendSuccess: 'Das Angebot wurde nochmal per E-Mail versendet.',
            resend: 'Erneut vesenden',
            resendConfirm: 'Sind Sie sicher, dass Sie das Angebot erneut an den Kunden senden möchten?',
            validUntil: 'Gültig bis:'
        },
        orderRecOverview: {
            title: 'Angebotsvorlagen',
            new: 'Neues Angebot erstellen',
            name: 'Vorlagenname',
            modified: 'Zuletzt geändert',
            sentTitle: 'Gesendete Angebote',
            sentDate: 'gesendet am',
            sentName: 'Angebotsname',
            customer: 'Kunde',
            customerNumber: 'Kundennummer',
            filterTemplates: 'Vorlagen filtern',
            filterOffers: 'Angebote filtern',
            deleteTemplateConfirm: 'Sind Sie sicher, dass Sie die Vorlage "{name}" löschen möchten?',
            deleteConfirm: 'Sind Sie sicher, dass Sie das Angebot "{name}" löschen möchten? Der Kunde kann sich alle Positionen weiterhin in seinem Konto ansehen.'
        },
        orderSeason: {
            title: 'Vororder Saisons',
            new: 'Neue Saison anlegen',
            name: 'Name der Saison',
            modified: 'Zuletzt geändert',
            filterOverview: 'Saisons filtern',
            deleteConfirm: 'Sind Sie sicher, dass Sie die Saison löschen wollen?'
        },
        mainNav: {
            orderRecTemplateOverview: 'Angebots-Übersicht',
            orderSeasonOverview: 'Vororder Saisons'
        },
        orderSeasonForm: {
            deadlines: "Termine",
            orderMode: {
                title: "Vororder-Typ",
                singleOrder: {
                    title: "[BETA] Termin-zentriert",
                    description: "(eine Order für jeden Liefertermin)"
                },
                multiOrder: {
                    brands: {
                        title: "Marken-zentriert",
                        description: "(für jede Marke eine Order für jeden Liefertermin)"
                    }
                }
            },
            discountTypes: {
                amount : "Rabattierung nach Bestellbetrag",
                quantity : "Rabattierung nach Bestellmenge"
            },
            name: 'Name der Saison',
            startDate: 'Erster Liefertermin',
            seasonEndDate: 'Ende der Saison',
            seasonStartDate: 'Start der Saison',
            seasonMinOrderAmount: 'Mindestbestellwert',
            seasonMinOderAmountPerShipment: 'pro Lieferfenster',
            send: 'Senden',
            export: 'CSV-Export',
            save: 'Neue Saison speichern',
            update: 'Änderungen speichern',
            articlesMissing: 'Bitte wählen sie Artikel für die Saison aus.',
            nameMissing: 'Bitte einen Namen vergeben',
            baseDiscount: 'Basis-Rabatt',
            volumeDiscount: 'Sortimentsbonus',
            hide: 'ausblenden',
            show: 'einblenden',
            link: 'Web-Adresse für den Kunden',
            saveSuccess: 'Die Saison wurde erfolgreich gespeichert',
            updateSuccess: 'Ihre Änderungen an der Saison wurden gespeichert',
            allowDeadlineSelection: 'exakten Liefertermin ermöglichen',
            usePeriods: 'Perioden basiert',
            periodTitle: 'Lieferperioden',
            discountsTitle: 'Rabatte',
            staggeredDiscountTitle: 'Staffelrabatt',
            generalDiscountTitle: 'Generelle Rabatte',
            shipmentDiscount: {
                title:'Sonderrabatt',
                status: 'Aktiv',
                minShipmentCount:'Mindestanzahl Lieferfenster',
                minAmountPerShipment : 'Mindestbestellwert pro Lieferfenster',
                percentage: 'Prozentsatz'
            },
        },
        login: {
            title: 'Anmelden',
            name: 'E-Mail / Benutzer',
            pass: 'Passwort',
            submit: 'Login',
            error: 'Die Zugangsdaten sind nicht korrekt.',
            unknownError: 'Es ist ein Fehler aufgetreten.',
            notAllowed: 'Die Vororder ist für Sie nicht freigeschaltet. Bitte wenden Sie sich an unseren Kundenservice.'
        },
        customerSelect: {
            notFound: 'Keine Übereinstimmungen'
        },
        dialog: {
            confirm: 'OK',
            cancel: 'Abbrechen'
        },
        discountInfo: {
            minOrderAmount: 'Für diese Vororder gilt ein Mindestbestellwert von {amount}.',
            minShipmentAmount: 'Für diese Vororder gilt ein Mindestbestellwert von {amount} pro Lieferfenster.',
            shipmentDiscountTitle: 'Sonderrabatt',
            shipmentDiscount: 'Sonderrabatt',
            deadlineTitle: 'Vororderschlußtermine',
            discountTitle: 'Vororderrabatte',
            volumeInfoTitle: 'Sortimentsbonus',
            baseInfoTitle: 'Basisrabatt',
            shipmentDiscountInfo: 'Der Sonderrabatt wird für alle Lieferfenster gewährt, wenn bei einer bestimmten Anzahl von Lieferfenstern ein bestimmter Mindestbestellwert überschritten wird. ',
            shipmentDiscountInfoAdditional: '',
            shipmentDiscountShipmentAmountText: '{percent}% Rabatt beim Erfüllen eines jeweiligen Mindestbestellwertes von {min} € in mindestens {count} Lieferfenstern',
            shipmentDiscountOrderAmountText: '{percent}% bei einem Vorordervolumen ab {min}',
            discountInfo: 'Vororderrabatte werden nur bei Bestellungen vor dem Orderschlußtermin gewährt. Der Vororderrabatt entfällt bei Nachbestellungen.',
            discountInfoAdditional: 'Die Rabatte werden auf die jeweilige Monatsorder angerechnet.',
            discountAmountText: '{percent}% bei einem Vorordervolumen ab {min}',
            discountQuantityText: '{percent}% bei einem Vorordermenge ab {min}',
            totalNetPrice: 'Zwischensumme',
            appliedStaggeredDiscount: 'Vororderabatt',
            appliedBaseDiscount: 'Basisrabatt',
            appliedVolumeDiscount: 'Sortimentsbonus',
            discountedNetPrice: 'Endsumme Vororder',
            volumeInfoDesc: '2% Sortimentsbonus bei Vororder aus allen Warengruppen. Jede Warengruppe muss mindestens 15% vom Auftragswert wiederspiegeln.',
            baseInfoDesc: 'Ab einem Auftragswert größer als {min} € bekommen sie einen {discount}%-igen Basisrabatt. Der Basisrabatt bleibt bei Nachbestellungen bestehen.',
            disclaimer: 'Größere Stornierungen oder Verschiebungen aus der Vororder bedingen einen Wegfall des gewährten Rabattes.'
        },
        volumeDiscountInfo: {
            total: 'Gesamt',
            volume: 'Volumen'
        },
        advanceOrderForm: {
            export: 'CSV-Export',
            invalidAmountsInOrder: 'Bitte kontrollieren Sie Ihre Mengeneingaben. Es müssen die Mindestbestellmenge und die Staffel beachtet werden.',
            logoutWithoutSave: 'Abmelden ohne zu speichern',
            logout: 'Abmelden',
            submit: 'Vororder verbindlich abschicken',
            submitConfirm: 'Vielen Dank, ihre Vororder wurde abgesendet.',
            save: 'Speichern',
            saveConfirm: 'Ihre Änderungen wurde erfolgreich gespeichert',
            minShipmentAmountNotFulfilled: 'Bitte beachten Sie den Mindestbestellwert von {min} für die Lieferfenster.',
            minOrderAmountNotFulfilled: 'Bitte beachten Sie den Mindestbestellwert von {min} für die Order.',
            terms: 'AGB',
            contact: 'Kontakt',
            condition: 'Mit Absenden der Vororder akzeptieren Sie die AGB'
        },
        discountScale: {
                title: 'Rabattstaffel',
                addDiscountLevel: '',
                removeLevel: 'Staffelrabatt entfernen',
                percentage: 'Rabatt in %',
            amount : {
                minNetSum: 'ab Bestellbetrag in €',
            },
            quantity : {
                minNetSum: 'ab Bestellmenge von',
            }
        },
        volumeDiscount:{
            info: "Bei Erfüllung der Sortiment-Bedingung wird ein Rabatt von 2 % gewährt."
        },
        baseDiscount: {
            activeLabel: 'Basisrabatt anbieten',
            conditionLabel: 'ab volumen in €',
            percentLabel: 'Rabatt in %'
        },
        noSeasonFound: {
            title: 'Es wurde keine Vororder-Saison gefunden',
            desc: 'Bitte überprüfen sie den Link',
            logout: 'Abmelden'
        },
        deadlineInfo: {
            orderDeadline: 'Bestellung bis:',
            firstShipment: 'Erstes Lieferdatum ab:'
        },
        advOrderDeadLine: {
            addDeadlineLevel: '',
            removeLevel: '',
            deadline: 'Vororderschlusstermin',
            startDate: 'Erster Liefertermin',
            endDate: 'Letzter Liefertermin'
        },
        brandNavigation: {
            title: 'Marken Auswahl'
        }
    },
    'en-GB': {
        shipmentHeader:{
            orderstop: 'Orderdeadline: ',
            shipmentPeriod : 'shipping window'
        },
        shipmentCalculation:{
            shipmentSum: 'interim sum',
            staggeredDiscount:'discount: preorder',
            shipmentCountDiscount: 'discount: shipment',
            shipmentTotal: 'shipment total',
            shipmentMinOrderAmount: 'shipment moq'

        },
        article: {
            nr: 'articlenumber',
            'lang-cluster': 'language cluster',
            description: 'description',
            color: 'color',
            info: 'info',
            moq: 'MOQ',
            ve: 'salesunit',
            'casepack-amount': 'casepack amount',
            gtin: 'GTIN13 / EAN',
            'net-price': 'price (net)',
            'recommended-list-price': 'rlp (gross)',
            'retail-price':'retail net price in EUR',
            'retail-rrp': 'RRP in EUR',
            'distributor-price': 'Distributor Price excl. VAT in EUR',
            'distributor-rrp': 'Sug. Retail Price excl. VAT in EUR',
            'amount': 'Quantity',
            'total-amount': 'quantity',
            'total-amount-brand': 'total',
            'total-net-price': 'total (net)',
            sheetTotalNetPrice: 'total',
            priceNotice: 'Prices shown are retail prices',
            emptyFilterResult: 'There are no matching results. Check the filter criteria.',
            amountHidden: 'Articles with quantities are hidden.',
            invalidAmount: 'Note the minimum quantity of {min} and the scale of {step}',
            showHiddenAmounts: 'show',
            customerOrderNumber: 'custom order number',
            customerOrderNumberLabel: 'order no..',
            customerOrderNumberTitle: 'order no. for {date}'
        },
        orderSheet: {
            hideEmptyPos: 'Show only items with quantity',
            search: 'Filter item by no, name, brand'
        },
        orderSentForm: {
            update: 'Save changes'
        },
        orderRecForm: {
            name: 'Offer name',
            customer: 'Customer',
            message: 'Offer text',
            send: 'Submit',
            save: 'Save as template',
            update: 'Save template',
            customerMissing: 'Please select a customer',
            nameMissing: 'Please assign a name',
            confirm: 'Send offer to customer',
            confirmTitle: 'Confirm offer',
            title: 'Offer',
            sentTitle: 'Offer',
            cancel: 'Cancel',
            back: 'Back to the overview',
            exportPDF: 'Export as PDF',
            sendSuccess: 'The offer was sent successfully.',
            saveSuccess: 'The template was saved successfully.',
            updateSentSuccess: 'The changes to the offer have been successfully saved.',
            updateSuccess: 'The changes to the template have been saved successfully.',
            unknownError: 'An error has occurred, please try again later.',
            resendSuccess: 'The offer was sent again by e-mail.',
            resend: 'Send again',
            resendConfirm: 'Are you sure you want to resend the quote to the customer?',
            validUntil: 'Valid until:'
        },
        orderRecOverview: {
            title: 'Offer templates',
            new: 'Create new offer',
            name: 'Template name',
            modified: 'Last modified',
            sentTitle: 'Sent offers',
            sentDate: 'sent on',
            sentName: 'Offer name',
            customer: 'Customer',
            customerNumber: 'Customer number',
            filterTemplates: 'Filter templates',
            filterOffers: 'Filter offers',
            deleteTemplateConfirm: 'Are you sure you want to delete the "{name}" template?',
            deleteConfirm: 'Are you sure you want to delete the "{name}" offer? The customer can still view all items in his account.'
        },
        orderSeason: {
            title: 'pre-order seasons',
            new: 'create new season',
            name: 'season name',
            modified: 'last modified',
            filterOverview: 'last modified',
            deleteConfirm: 'Are you sure you want to delete the season?'
        },
        mainNav: {
            orderRecTemplateOverview: 'offer overview',
            orderSeasonOverview: 'pre-order seasons'
        },
        orderSeasonForm: {
            deadlines: "Dates",
            shipmentDiscount: {
                title:'special discount',
                status: 'active',
                minShipmentCount:'minimum number of shipment windows',
                minAmountPerShipment : 'minimum amount per shipment window',
                percentage: 'percentage'
            },
            orderMode: {
                title: "pre-order type",
                singleOrder: {
                    title: "appointment-centered",
                    description: "(one order for each delivery date)"
                },
                multiOrder: {
                    brands: {
                        title: "brand-centered",
                        description: "(for each brand one order for each delivery date)"
                    }
                }
            },
            discountTypes: {
                amount : "discounting according to order amount",
                quantity : "discounting according to order quantity"
            },
            name: 'Name of season',
            startDate: 'First delivery date',
            seasonEndDate: 'End of season',
            seasonStartDate: 'Start of season',
            send: 'Submit',
            save: 'Save new season',
            update: 'Save changes',
            articlesMissing: 'Please select items for the season.',
            nameMissing: 'Please assign a name',
            baseDiscount: 'Base discount',
            volumeDiscount: 'Assortment bonus',
            hide: 'hide',
            show: 'show',
            link: 'Web address for the customer',
            saveSuccess: 'The season was successfully saved',
            updateSuccess: 'Your changes to the season have been saved',
            allowDeadlineSelection: 'enable exact delivery date',
            usePeriods: 'Periods based',
            periodTitle: 'Delivery periods',
            discountsTitle: 'Discounts',
            staggeredDiscountTitle: 'Graduated discount',
            generalDiscountTitle: 'General discounts'
        },
        login: {
            title: 'Login',
            name: 'E-mail / User',
            pass: 'Password',
            submit: 'Login',
            error: 'The access data are incorrect.',
            unknownError: 'An error has occurred.',
            notAllowed: 'The pre-order is not activated for you. Please contact our customer service.'
        },
        customerSelect: {
            notFound: 'No matches'
        },
        dialog: {
            confirm: 'OK',
            cancel: 'Cancel'
        },
        discountInfo: {
            minOrderAmount: 'A minimum order value of {amount} is set for this pre-order.',
            minShipmentAmount: 'A minimum order value of {amount} per shipment window is set for this pre-order.',
            shipmentDiscountTitle: 'shipment discount',
            deadlineTitle: 'preorder deadlines',
            discountTitle: 'preorder discounts',
            volumeInfoTitle: 'assortment bonus',
            baseInfoTitle: 'base discount',
            discountInfo: 'Pre-order discounts are only granted for orders placed before the order deadline. The pre-order discount does not apply to subsequent orders.',
            discountInfoAdditional: 'Discounts are applied to the respective monthly order.',
            discountAmountText: '{percent}% with a preorder volume from {min}',
            discountQuantityText: '{percent}% for a preorder quantity from {min}',
            shipmentDiscountInfo: 'When a certain minimum order amount is reached in a certain number of different shipment windows then the shipment discount is applied to all shipment windows.',
            shipmentDiscountInfoAdditional: '',
            shipmentDiscountShipmentAmountText: '{percent}% discount on reaching an minimal order amount of {min} € in atleast {count} shipping windows',
            shipmentDiscountOrderAmountText: '{percent}% bei einem Vorordervolumen ab {min}',
            totalNetPrice: 'Subtotal',
            appliedStaggeredDiscount: 'pre-order discount',
            appliedBaseDiscount: 'Base discount',
            appliedVolumeDiscount: 'assortment bonus',
            discountedNetPrice: 'final total pre-order',
            volumeInfoDesc: '2% assortment bonus for pre-orders from all merchandise categories. Each product group must reflect at least 15% of the order value.',
            baseInfoDesc: 'From an order value greater than {min} € you get a {discount}% basic discount. The basic discount remains valid for repeat orders.',
            disclaimer: 'Major cancellations or postponements from the preorder will result in the loss of the discount granted.'
        },
        volumeDiscountInfo: {
            total: 'Total',
            volume: 'Volume'
        },
        advanceOrderForm: {
            logoutWithoutSave: 'Logout without saving',
            logout: 'Logout',
            submit: 'Send binding preorder',
            submitConfirm: 'Thank you, your preorder has been sent.',
            save: 'Save',
            saveConfirm: 'Your changes were successfully saved',
            terms: 'Terms and conditions',
            contact: 'Contact',
            condition: 'By sending the preorder you accept the terms and conditions'
        },
        discountScale: {
            title: 'Discount rate',
            addDiscountLevel: '',
            removeLevel: 'Remove scale discount',
            percentage: 'Discount in %',
            amount : {
                minNetSum: 'from order amount in €',
            },
            quantity : {
                minNetSum: 'from order quantity of',
            }
        },
        volumeDiscount:{
            info: "If the assortment condition is met, a discount of 2% is granted."
        },
        baseDiscount: {
            activeLabel: 'Offer base discount',
            conditionLabel: 'as of volume in €',
            percentLabel: 'Discount in %'
        },
        noSeasonFound: {
            title: 'No preorder season was found',
            desc: 'Please check the link',
            logout: 'Logout'
        },
        deadlineInfo: {
            orderDeadline: 'Order until:',
            firstShipment: 'First delivery date from:'
        },
        advOrderDeadLine: {
            addDeadlineLevel: '',
            removeLevel: '',
            deadline: 'Preorder deadline',
            startDate: 'First delivery date',
            endDate: 'Last delivery date'
        },
        brandNavigation: {
            title: 'Brands selection'
        }
    }
}

export default messages;
