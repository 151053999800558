<template>
  <A v-if="!hasShipmentDiscount && !hasStaggeredDiscounts"
     :discount-scale="discountScale"
     :shipment-discount="shipmentDiscount"
     :shipment-dates="shipmentDates"
     :hidden-amount-hint="hiddenAmountHint"
     :show-totals="showTotals"
     :brands="brands"
     :articles="articles"
     :allow-custom-order-number="allowCustomOrderNumber"
     @reset-filter-and-show-amounts="$emit('reset-filter-and-show-amounts')"></A>
  <AA v-else-if="!hasShipmentDiscount && hasStaggeredDiscounts"
      :discount-scale="discountScale"
      :shipment-discount="shipmentDiscount"
      :shipment-dates="shipmentDates"
      :hidden-amount-hint="hiddenAmountHint"
      :show-totals="showTotals"
      :brands="brands"
      :articles="articles"
      :allow-custom-order-number="allowCustomOrderNumber"
      @reset-filter-and-show-amounts="$emit('reset-filter-and-show-amounts')"
  ></AA>
  <AAA v-else-if="hasShipmentDiscount && !hasStaggeredDiscounts"
       :discount-scale="discountScale"
       :shipment-discount="shipmentDiscount"
       :shipment-dates="shipmentDates"
       :hidden-amount-hint="hiddenAmountHint"
       :show-totals="showTotals"
       :brands="brands"
       :articles="articles"
       :allow-custom-order-number="allowCustomOrderNumber"
       @reset-filter-and-show-amounts="$emit('reset-filter-and-show-amounts')"></AAA>
  <AAAA v-else-if="hasShipmentDiscount && hasStaggeredDiscounts"
        :discount-scale="discountScale"
        :shipment-discount="shipmentDiscount"
        :shipment-dates="shipmentDates"
        :hidden-amount-hint="hiddenAmountHint"
        :show-totals="showTotals"
        :brands="brands"
        :articles="articles"
        :allow-custom-order-number="allowCustomOrderNumber"
        @reset-filter-and-show-amounts="$emit('reset-filter-and-show-amounts')"></AAAA>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import OrderSheetShipmentCalculation from "@/FrontEnd/components/OrderSheetShipmentCalculation";
import Test from "@/FrontEnd/components/Test";
import OrderSheetShipmentSum from "@/FrontEnd/components/OrderSheetShipmentSum";
import OrderSheetShipmentStaggeredDiscount from "@/FrontEnd/components/OrderSheetShipmentStaggeredDiscount";
import OrderSheetShipmentTotal from "@/FrontEnd/components/OrderSheetShipmentTotal";
import A from "@/FrontEnd/components/A";
import AA from "@/FrontEnd/components/AA";
import AAA from "@/FrontEnd/components/AAA";
import AAAA from "@/FrontEnd/components/AAAA";
export default {
    name: "OrderSheetFooter",
  components: {A,AA,AAA,AAAA,OrderSheetShipmentStaggeredDiscount, OrderSheetShipmentSum, OrderSheetShipmentCalculation,OrderSheetShipmentTotal},
  data (){
    return {

    }
  },
  props: {
        pricegroup:{
          type: String,
          default: 'EH'
        },
        discountScale: {
          type: Array
        },
        baseDiscount: {
          type: Object,
          default: null
        },
        shipmentDiscount: {
            type: Object
        },
        periodMode:{
              type:Boolean
        },
        volumeDiscounts: {
          type: Array,
          default: null
        },
        amountDiscounts: {
          type: Array,
          default: null
        },
        articles: {
          type: Array
        },
    deadlines:{
      type: Array
    },
        shipmentDates: ShipmentDates,
        showTotals: Boolean,
        hiddenAmountHint: Boolean,
        brands: Array,
        allowCustomOrderNumber: {
            type: Boolean,
            default: false
        }
    },
    created(){

    },
    computed: {
          hasShipmentDiscount(){
            return this.shipmentDiscount.active;
          },
          hasStaggeredDiscounts(){

            return this.discountScale !== null && this.discountScale.length > 0;

          },

          totalNetPrice(shipment) {

              return 0;
            }




    },
    methods: {
        calculateShipmentAmountSum(shipmentId){

          return this.shipmentDates.getShipmentById(shipmentId).getTotalNetPrice(this.articles);

          /*for (let brand of this.articles) {
            for (let article of brand.articles) {
              if(!(articleId in this.articleAmounts)) {
                return 0;
              }
              return this.articleAmounts[articleId];
              total += (this.getAmount(article.id) * article.netPrice);
            }
          }
          return total;

          if(!(articleId in this.articleAmounts)) {
            return 0;
          }
          return this.articleAmounts[articleId];*/

        },
        calculateColSpan() {
            return 9 + this.shipmentDates.getAmountOfDates() + (this.shipmentDates.getAmountOfDates() > 1 ? 1 : 0);
        },
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/table";
    @import "@/FrontEnd/styles/input";

    .discount-info-wrapper {
      margin-top: .4rem;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-items: flex-end;
      flex-wrap: wrap;

      &>.discount-info-tabs {
        flex-grow: 1;
        width: 60%;
        min-width: 40rem;

        &>nav {
          display: flex;
          flex-direction: row;
          padding: 0;
          margin: 0;

          &>li {
            margin: 0;
            padding: 0;
            list-style: none;
            width: auto;

            &>a {
              position: relative;
              display: block;
              padding: .6rem;
              border: .1rem solid #dddddd;
              border-bottom: none;
              width: auto;
              text-decoration: none;
              background: linear-gradient(to bottom, #fff 0%, #eee 100%);
              z-index: 0;


              &:active, &:visited, &:focus {
                text-decoration: none;
                color: currentColor;
              }
              &:hover {
                color: #004489;
                background: #fff;
              }
              &.active {
                background: #fff;
                z-index: 5;
                &:hover {
                  color: currentColor;
                }
              }
            }
          }
        }
        &>aside {
          position: relative;
          margin-top: -.1rem;
          box-sizing: border-box;
          border: .1rem solid #dddddd;
          padding: .4rem;
          background: #fff;
          z-index: 3;
        }
      }
      &>.discount-calc-table {
        flex-grow: 1;
        width: 40%;
        min-width: 30rem;
        box-sizing: border-box;
        padding: .4rem;

        & span {
          display: block;
          text-align: left;
          margin: .4rem 0;
        }
      }
      .discount-scale-table {
        margin: 0 auto;
        width: auto;
      }
    }
    tfoot td, tfoot th {
        background-color: $main-bg-color;

        & input[type=text] {
            width: 7.2rem;
        }

        &.total-amount{
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            height: 2.8rem;
            box-sizing: border-box;

            & .price-notice {
                float: left;
            }
        }
        &.hint, &.customer-order-numbers {
          position: -webkit-sticky;
          position: sticky;
          bottom: 5.5rem;

          &.hint {
            color: crimson;
          }
        }
          &.total {
            text-align: right;
            position: -webkit-sticky;
            position: sticky;
            bottom: 0rem;

            &.hint {
              color: crimson;
            }
          }
          &.sum {
            text-align: right;
            position: -webkit-sticky;
            position: sticky;
            bottom: 4rem;

            &.hint {
              color: crimson;
            }
          }
            &.staggered {
              text-align: right;
              position: -webkit-sticky;
              position: sticky;
              bottom: 2.5rem;

              &.hint {
                color: crimson;
              }
            }
              &.bonus {
                text-align: right;
                position: -webkit-sticky;
                position: sticky;
                bottom: 4rem;

                &.hint {
                  color: crimson;
                }
              }
      &.minOrderAmount {
        text-align: right;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;

        &.hint {
          color: crimson;
        }
      }

            &.customer-order-numbers {
                text-align: right;
            }
        }
</style>