import Vue from 'vue'
import App from './FrontEnd/App.vue'
import VueI18n from "vue-i18n";
import messages from "@/FrontEnd/i18n/lang";
import dateTimeFormats from "@/FrontEnd/i18n/dateTimeFormats";
import numberFormats from "@/FrontEnd/i18n/numberFormats";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faArrowLeft, faChevronDown, faChevronUp,
    faFilePdf, faMinus,
    faPen,
    faPlus,
    faSave, faSearch,
    faShare,
    faSignOutAlt,
    faTimes, faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import vSelect from 'vue-select';

Vue.config.productionTip = false
Vue.use(VueI18n);

import router from "@/FrontEnd/router";
import store from "@/FrontEnd/store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(faPen);
library.add(faSave);
library.add(faShare);
library.add(faPlus);
library.add(faSignOutAlt);
library.add(faFilePdf);
library.add(faArrowLeft);
library.add(faTimes);
library.add(faSearch);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faTrashAlt);
library.add(faMinus);

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);

const i18n = new VueI18n({
    locale: 'de-DE',
    fallbackLocale: 'de-DE',
    messages,
    dateTimeFormats,
    numberFormats
});

store.commit('init');
new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
