<template>
  <div>
    <span style="text-align:center;width:10%;min-width:2rem;float:left" >{{ staggeredDiscount + "%" }}</span><span style="text-align: right;width:auto;float:right">{{ "- " + staggeredDiscountAmount  }}</span>
  </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
  name: "OrderSheetShipmentStaggeredDiscount",
  props: {
    discountScale: Array,
    shipmentId : Number,
    shipments: {
      type: ShipmentDates
    }

  },
  computed:{
    shipmentSum(){
      let shipment = this.shipments.getShipmentById(this.shipmentId);

      return shipment.totalAmount;
    },
    shipmentQuantity(){
      let shipment = this.shipments.getShipmentById(this.shipmentId);

      return shipment.totalQuantity;
    },

    formatter(){
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'})
    },
    staggeredDiscountAmount(){
        let shipment = this.shipments.getShipmentById(this.shipmentId);
        shipment.staggeredDiscount = this.shipmentSum * (this.staggeredDiscount/100);
        return this.formatPrice(shipment.staggeredDiscount );
    },
    staggeredDiscount(){
      let discounts = JSON.parse(JSON.stringify(this.discountScale));
      let shipment = this.shipments.getShipmentById(this.shipmentId);

      let type = discounts[0].type;
      if(type === 'quantity'){
        for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
          if (shipment.totalQuantity >= discount.min) {
            return discount.percent;
          }
        }
      }else{
        for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
          if (shipment.totalAmount >= discount.min) {
            return discount.percent;
          }
        }
      }

      return 0;
    }
  },
  methods:{
    formatPrice(price){
      return this.formatter.format(price);
    },
  }
}
</script>

<style scoped>

</style>