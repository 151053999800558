<template>
    <header class="title-wrapper">
        <LanguageSelector :initLocale="initLocale"></LanguageSelector>
        <div class="title-logo"><img :src="require('@/FrontEnd/assets/logo-katadyn-group_350.png')"></div>
        <div>
            <slot></slot>
        </div>
        <div></div>
    </header>
</template>

<script>
import LanguageSelector from "@/FrontEnd/components/LanguageSelector";
export default {
name: "AdvanceOrderTitle",
  components: {LanguageSelector},
  props:{
      initLocale: String
  }
}
</script>

<style scoped lang="scss">
    .title-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: $regular-padding;

        &>* {
            width: 33%;
        }
    }
    .title-logo {
        text-align: left;
    }
</style>