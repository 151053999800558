<template>
  <div>
  <table ref="article-table" style="width:100%">
    <tr v-if="allowCustomOrderNumber" style="width:100%">
      <th colspan="10" class="customer-order-numbers">{{ $t('article.customerOrderNumber') }}</th>
      <td v-for="date in shipmentDates.dates" :key="date.id" class="customer-order-numbers">
        <input
            type="text"
            class="slim"
            :placeholder="$t('article.customerOrderNumberLabel')"
            :title="$t('article.customerOrderNumberTitle', {date: $d(date.date, 'short')})"
            v-model="date.orderNumber"
        >
      </td>
      <td colspan="2" class="customer-order-numbers"></td>
    </tr>
    <tr>
      <td colspan="9" class="total-amount">
        <span class="price-notice"></span>
      </td>
      <td class="total-amount number">
        <table>
          <tbody>
          <tr>
            <td>{{ $t('shipmentCalculation.shipmentSum') }}</td>
          </tr>
          <tr>
            <td>{{ $t('shipmentCalculation.staggeredDiscount') }}</td>
          </tr>
          <tr>
            <td>{{ $t('shipmentCalculation.shipmentCountDiscount') }}</td>
          </tr>
          <tr>
            <td>{{ $t('shipmentCalculation.shipmentTotal') }}</td>
          </tr>
          <!-- <tr v-if="hasDiscountScale">
             <td>{{ $t('discountInfo.appliedStaggeredDiscount') }}</td>
             <td>{{ staggeredDiscountPercentage }}%</td>
             <td>PREIS2{{ formatPrice(staggeredDiscountAmount) }}</td>
           </tr>
           <tr>
             <td>{{ $t('discountInfo.discountedNetPrice') }}</td>
             <td></td>
             <td>{{  $n(finalNetPrice, 'currency') }}</td>
           </tr>-->
          </tbody>
        </table>

      </td>
      <td
          v-for="date in shipmentDates.dates"
          :key="date"
          class="total-amount number">
        <OrderSheetShipmentCalculation
            :discount-scale="discountScale"
            :base-discount="baseDiscount"
            :shipment="date"
            :volume-discounts="volumeDiscounts"
            :articles="brands"
            :deadlines="deadlines"
            :period-mode="periodMode"
        ></OrderSheetShipmentCalculation>
      </td>
      <td class="total-amount number" :colspan="2">
              <span v-if="showTotals">
                  {{ $t('article.sheetTotalNetPrice') }} {{ $n(totalNetPrice, 'currency') }}
              </span>
      </td>
    </tr>
  </table>
  </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import OrderSheetShipmentCalculation from "@/FrontEnd/components/OrderSheetShipmentCalculation";
import Test from "@/FrontEnd/components/Test";
export default {
  name: "OrderSheetFooter",
  components: {OrderSheetShipmentCalculation,Test},
  props: {
    discountScale: {
      type: Array
    },
    baseDiscount: {
      type: Object,
      default: null
    },
    periodMode:{
      type:Boolean
    },
    volumeDiscounts: {
      type: Array,
      default: null
    },
    amountDiscounts: {
      type: Array,
      default: null
    },
    articles: {
      type: Array
    },
    deadlines:{
      type: Array
    },
    shipmentDates: ShipmentDates,
    showTotals: Boolean,
    hiddenAmountHint: Boolean,
    brands: Array,
    allowCustomOrderNumber: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalNetPrice() {
      return this.shipmentDates.getTotalNetPrice(this.brands);
    }
  },
  methods: {
    calculateColSpan() {

      return 9 + this.shipmentDates.getAmountOfDates() + (this.shipmentDates.getAmountOfDates() > 1 ? 1 : 0);
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/table";
@import "@/FrontEnd/styles/input";

tfoot td, tfoot th {
  background-color: $main-bg-color;

  & input[type=text] {
    width: 7.2rem;
  }

  &.total-amount{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    height: 2.8rem;
    box-sizing: border-box;

    & .price-notice {
      float: left;
    }
  }
  &.hint, &.customer-order-numbers{
    position: -webkit-sticky;
    position: sticky;
    bottom: 2.8rem;
    &.hint {
      color: crimson;
    }
    &.customer-order-numbers {
      text-align: right;
    }
  }
}
</style>