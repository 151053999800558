<template>
  <div>
    <table>
        <thead >
            <tr>
                <th>{{ $t('advOrderDeadLine.deadline') }}</th>
                <th>{{ $t('advOrderDeadLine.startDate') }}</th>
                <th>{{ $t('advOrderDeadLine.endDate') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(deadline, index) in deadlines" :key="index">
                <td style="padding-bottom: 0.5rem">
                    <datepicker
                        class="slim"
                        :language="de"
                        monday-first
                        v-model="deadline.date"
                        :id="'deadline-' + index"
                        :format="formatDate"
                    ></datepicker>
                </td>
                <td style="padding-bottom: 0.5rem">
                  <datepicker
                      class="slim"
                      :language="de"
                      monday-first
                      v-model="deadline.startDate"
                      :id="'start-date-' + index"
                      :format="formatDate"
                  ></datepicker>
                </td>
                <td style="padding-bottom: 0.5rem">
                    <datepicker
                        class="slim"
                        :language="de"
                        monday-first
                        v-model="deadline.endDate"
                        :id="'end-date-' + index"
                        :format="formatDate"
                    ></datepicker>
                </td>
              <button v-if="isNotLastDeadline" @click="deleteLevel(index)" class="slim" :title="$t('advOrderDeadLine.removeLevel')">
                <fa-icon icon="minus"></fa-icon>
              </button>

            </tr>
        </tbody>
    </table>
    <div style="text-align: center">
      <button @click="addLevel" class="slim"><fa-icon icon="plus"></fa-icon> {{ $t('advOrderDeadLine.addDeadlineLevel') }}</button>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {de} from 'vuejs-datepicker/dist/locale';

export default {
    name: "AdvanceOrderDeadlineEditor",
    components: { Datepicker },
    data() {
        let today = new Date();
        let in3Months = new Date();
        in3Months.setMonth(today.getMonth() + 3);
        return {
          startDate: today,
          endDate: in3Months,
          de
        };
    },
    props: {
        value: Array
    },
    computed: {
        isNotLastDeadline: function(){
            return this.value.length > 1;
        },
        deadlines: {
            get() {
                return this.value;
            },
            set(deadlines) {
                this.$emit('input', deadlines);
            }
        }
    },
    methods: {
        addLevel() {
          this.deadlines.push({date: new Date(), startDate: this.startDate, endDate: this.endDate});
        },
        deleteLevel(index) {
          this.deadlines.splice(index, 1);
        },
        formatDate(date) {
            return this.$d(date, 'short');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    .vdp-datepicker {
        &.slim /deep/ input {
            @include slim-style;
        }
    }
    table {
        max-width: 60rem;
        & tr {
            background-color: white;
        }
    }
    .deadline-wrapper {


    }


    .date{
      min-width: 9rem;
      padding: $input-slim-padding;
      &>span {
        display: inline-block;
        width: 100%;


        & .shipment-date /deep/ input {
          @include input-style;
          width: 100%;
          color:black
        }
      }
    }



    .addButton{
      border-radius: 50%;
      border:none;
      background: $brand-color;
      color:white;
      line-height: 2rem;
      height:2.35rem;

    }

    .deleteButton{
      border-radius: 50%;
      border:none;
      background: linear-gradient(#565656 0%, #444 69%);
      color:white;
      line-height: 2rem;
      height:2.35rem;
    }
</style>