import { render, staticRenderFns } from "./VolumeDiscountInfo.vue?vue&type=template&id=03375804&scoped=true&"
import script from "./VolumeDiscountInfo.vue?vue&type=script&lang=js&"
export * from "./VolumeDiscountInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03375804",
  null
  
)

export default component.exports