const accents      = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
const replacements = "aaaaeeeeiiiioooouuuunc------";

const prefix = 'article-brand-';

let anchors = [];
let takenIds = [];

function slugifyGroupName(name) {
    if (name === undefined) {
        return null;
    }
    let slug = name.replace(/^\s+|\s+$/g, ''); // trim
    slug = slug.toLowerCase();

    // remove accents, swap ñ for n, etc
    for (let i=0, l=accents.length ; i<l ; i++) {
        slug = slug.replace(new RegExp(accents.charAt(i), 'g'), replacements.charAt(i));
    }

    slug = slug.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return slug;
}

export function buildBrandIndex(brands, rebuild) {
    brands = JSON.parse(JSON.stringify(brands));

    anchors = brands.reduce(function (carry, brand) {
        let id = brand.id;
        let name = brand.name;
        if (brand.id.includes(':')) {
            id = brand.id.split(':')[0];
            name = brand.name.split(/ - /)[0];
        }
        if (carry.find(elem => elem.id === id) === undefined) {
            carry.push({id, name, anchor: prefix + slugifyGroupName(name)});
        }
        return carry;
    }, rebuild ? [] : anchors);

    if (rebuild) {
        takenIds = [];
    }

    return anchors;
}


export function getBrandAnchor(id, asLink = false) {
    if (!(id instanceof String)) {
        id = String(id);
    }
    if (id.includes(':')) {
        id = id.split(':')[0];
    }
    if (!asLink) {
        if (takenIds.includes(id)) {
            return null;
        }
        takenIds.push(id);
    }

    return anchors.find(brand => brand.id === id).anchor;
}