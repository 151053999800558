<template>
  <div>
   {{ formatPrice(staggeredDiscountAmount) }}

     <!-- <tr v-if="hasDiscountScale">
        <td>{{ $t('discountInfo.appliedStaggeredDiscount') }}</td>
        <td>{{ staggeredDiscountPercentage }}%</td>
        <td>PREIS2{{ formatPrice(staggeredDiscountAmount) }}</td>
      </tr>
      <tr>
        <td>{{ $t('discountInfo.discountedNetPrice') }}</td>
        <td></td>
        <td>{{  $n(finalNetPrice, 'currency') }}</td>
      </tr>-->
  </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import Shipment from "@/FrontEnd/models/Shipment";

export default {
  name: "OrderSheetShipmentCalculation",
  props: {
    discountScale: {
      type: Array
    },
    baseDiscount: {
      type: Object,
      default: null
    },
    volumeDiscounts: {
      type: Array,
      default: null
    },
    amountDiscounts: {
      type: Array,
      default: null
    },
    periodMode: {
      type: Boolean,
      default: false
    },
    shipment: Shipment,
    articles: Array,
    deadlines: Array
  },


  computed: {
    formatter(){

      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'})
    },

    staggeredDiscountAmountFormatted(){
      return this.formatPrice(this.staggeredDiscountAmount);
    },

    totalNetPriceFormatted() {
      return this.formatPrice(this.totalNetPrice);
    },
    totalNetPrice() {
      return this.shipment.getTotalNetPrice(this.articles);
    },
    totalQuantity(){
      return this.shipment.getTotalQuantity(this.articles);
    },

    staggeredDiscountPercentage() {

      let discounts = JSON.parse(JSON.stringify(this.discountScale));

      let type = discounts[0].type;

      if(type === 'quantity'){
        for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
          if (this.totalQuantity >= discount.min) {
            return discount.percent;
          }
        }
      }else{
        for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
          if (this.totalNetPrice >= discount.min) {
            return discount.percent;
          }
        }
      }
      return 0;
    },
    staggeredDiscountAmount() {
      return this.totalNetPrice * (this.staggeredDiscountPercentage / 100);
    },


    finalNetPrice() {
      return (
          this.totalNetPrice - this.staggeredDiscountAmount
      );
    },
    flatArticleIds() {
      let articles = JSON.parse(JSON.stringify(this.articles));
      return articles.reduce((flatArticles, brand) => flatArticles.concat(brand.articles), []);
    },
    hasDiscountScale() {
      return this.discountScale !== null && this.discountScale.length > 0;
    },
  },
  methods: {
    formatPrice(price){
      return this.formatter.format(price);
    },
    handleVolumeDiscountChange({applies}) {
      this.volumeDiscountApplies = applies;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/table";
@import "@/FrontEnd/styles/input";

tfoot td, tfoot th {
  background-color: $main-bg-color;

& input[type=text] {
    width: 7.2rem;
  }

&.total-amount{
   position: -webkit-sticky;
   position: sticky;
   bottom: 0;
   height: 2.8rem;
   box-sizing: border-box;

& .price-notice {
    float: left;
  }
}
&.hint, &.customer-order-numbers{
           position: -webkit-sticky;
           position: sticky;
           bottom: 2.8rem;
&.hint {
   color: crimson;
 }
&.customer-order-numbers {
   text-align: right;
 }
}
}
</style>