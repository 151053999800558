<template>
    <div class="navigation-wrapper">
        <span class="nav-tab">{{ $t('brandNavigation.title') }}</span>
        <div class="brand-list" v-show="!hideBrands">
            <nav>
                <li v-for="brand in condensedBrands" :key="brand.id">
                    <router-link @click.native="hideList" :to="`#${brand.anchor}`">
                        {{ brand.name }}
                    </router-link>
                </li>
            </nav>
        </div>
    </div>
</template>

<script>
import {buildBrandIndex} from "@/FrontEnd/utils/brandAnchorManager";

export default {
    name: "BrandNavigation",
    props: {
        brands: Array
    },
    data() {
        return {
            hideBrands: false
        }
    },
    computed: {
        condensedBrands() {
            return buildBrandIndex(this.brands);
        }
    },
    methods: {
        hideList() {
            this.hideBrands = true;
            setInterval(() => this.hideBrands = false, 100);
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    @import "@/FrontEnd/styles/navigation";

    .navigation-wrapper {
        margin: 0 !important;
        position: relative;

        & .nav-tab {
            @include nav-tab;
            margin: 0;
        }

        & .brand-list {
            position: absolute;
            left: 0;
            display: none;
            z-index: 100;

            & nav {
                padding-top: $regular-padding;
                background-color: $main-bg-color;
                box-shadow: $highlight-drop-shadow;
                display: flex;
                width: 90vw;
                height: 20rem;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: flex-start;
            }
            & li {
                padding: $regular-padding;
                list-style: none;
                margin: 0;

                & a, & a:active, & a:visited {
                    color: #000;
                    text-decoration: none;
                }
                & a:hover {
                    color: $brand-color;
                    padding-left: $slim-padding;
                }
            }
        }

        &:hover {
            & .brand-list {
                display: block;
            }
            & .nav-tab {
                @include nav-tab-highlight;
            }
        }
    }
</style>